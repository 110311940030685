import { Component, Output, Inject, EventEmitter, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { FilterService } from '../../services/filter.service';
import { Subject } from 'rxjs';
import { Utils } from '../../utils/utils';
import { Router } from '@angular/router';
import { ComponentTool } from '../../interfaces/componenttool';

@Component({
    selector: 'stage',
    template: `Stage:<br />
                <ng-select (change)="onSelect($event)" style="width:200px; border: 1px white solid" class="top-main-select"
                    [items]="list"
                   bindLabel="label"
                   bindValue="value"
                   [closeOnSelect]="true"
                   [searchable]="false"
                   [ngModel]="defaults.stage">
                </ng-select>`,
    styleUrls: ['../../style/mat-select.component.less']
})
export class StageComponent implements OnInit, OnChanges {

    //@Output() tenantChanged = new EventEmitter<string>();
    @Input() reload: number = 1;

    @Output() stageChanged = new EventEmitter<string>();
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    public list: any[] = []
    subscriptionStage: any;
    componentToolEnum: typeof ComponentTool = ComponentTool;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , private saveService: SaveService
        , private filterservice: FilterService
        , private router: Router
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.subscriptionStage = this.defaults.languageMessageChanges$.subscribe(() => { this.ngOnChanges; this.subscriptionStage = null });
    }

    ngOnChanges() {
        this.list = []
        if (
            ((!this.defaults.isHomeUser || this.defaults.isadminuser) && (this.defaults.isCountryBackendProdUser || this.defaults.isProdUserManagementPortalUser))
            || (this.defaults.isProdUserManagementPortalUser && this.defaults.isHomeUser)
            || this.defaults.isEMTManagerUser || this.defaults.isMFGAdminPDCSUser)
            this.list.push({ value: 1, label: 'Production' })
        if (this.defaults.isHomeUser)
            this.list.push({ value: 3, label: 'Production (Home)' })
        if (this.defaults.isCountryBackendStableUser || this.defaults.isHomeUser || this.defaults.isDevUserManagementPortalUser
            || this.defaults.isDomainExpertUser || this.defaults.isExternalUser || this.defaults.isEMTManagerUser
            || this.defaults.isMFGAdminPDCSUser)
            this.list.push({ value: 0, label: 'Stable' })
        if (this.defaults.isExternalUser || this.defaults.isadminuser)
            this.list.push({ value: 2, label: 'Dev' })

        //if (this.defaults.isUMPDataAnalyticsUser && this.defaults.componentTool === this.componentToolEnum.Rulebook) {
        //    this.list.push({ value: 4, label: 'O365 (IT) Tenant' })
        //}

        if (this.list.length > 0)
            this.reloadPossibleValues()
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    editEnvSession() {
        if (this.defaults.loadInitialData === true) {
            const url = this.baseUrl + Utils.getGenericAPI() + this.defaults.stage;
            this.http
                .put<boolean>(url, this.defaults.stage)
                .subscribe(() => {
                    this.defaults.umpFilters = {
                        cdpList: [],
                        nodeId: [],
                        nodes: [],
                        page: 0,
                        pageSize: 12,
                        personaId: [],
                        personas: [],
                        tenant: [],
                        tenants: [],
                        userPrincipalName: ''
                    }
                   
                    if (window.location.href.indexOf('Group') > 0 || window.location.href.indexOf('EditUser') > 0 || window.location.href.indexOf('UMPAnalytics') > 0 ) {
                        this.router.navigate(["UmpHome"]).then(() =>
                            this.defaults.stageAfterRedirect = this.defaults.stage)
                    }
                    else {
                        this.defaults.stageAfterRedirect = this.defaults.stage
                    }
                    this.stageChanged.emit(this.defaults.stage.toString());
                    this.defaults.onStageChanged.emit(this.defaults.stage.toString());
                    this.defaults.notifyStageChange(this.defaults.stage.toString());
                    

                }, error => Utils.writeLog(this.http, this.baseUrl, error));
        }
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.filterservice.setToInitiate(true);
        this.editEnvSession();
    }

    onSelect(env: any) {
        if (!env || !this.defaults.tenants)
            return;

        this.defaults.stage = Number(env.value);
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();
    }

    onYes() {
        this.saveService.fireSave();
        this.editEnvSession();
    }

    reloadPossibleValues() {
        const url = window.location.href
        if (this.checkComponent(url, 'testcases')) {
            this.list = this.list.filter(x => x.value !== 4)
            if (this.defaults.stage === 1) {
                if (this.defaults.isExternalUser) this.defaults.stage = 2
                else this.defaults.stage = 0
                this.editEnvSession()
            }
        }
        else if (this.checkComponent(url, 'UmpHome') ||
            this.checkComponent(url, 'Group') ||
            this.checkComponent(url, 'EditUser') ||
            this.checkComponent(url, 'UMPAnalytics')) {
            this.list = this.list.filter(x => x.value !== 2 && x.value !== 3)
            if (this.defaults.isDevUserManagementPortalUser === false && this.defaults.isEMTManagerUser === false)
                this.list = this.list.filter(x => x.value !== 0)
            if (this.defaults.isProdUserManagementPortalUser === false && this.defaults.isEMTManagerUser === false)
                this.list = this.list.filter(x => x.value !== 1)


            if (this.defaults && this.list.length > 0) {
                var found = this.list.filter(x => x.value === this.defaults.stage).length
                if (found < 1 && this.list.length > 0) {
                    this.defaults.stage = this.list[0].value
                    this.editEnvSession();
                }
            }
        }
        else if (this.checkComponent(url, 'tenantdialerconfig')) {
            this.list = this.list.filter(x => x.value !== 3 && x.value !== 4)
            if (this.defaults && this.list.length > 0) {
                var found = this.list.filter(x => x.value === this.defaults.stage).length
                if (found < 1 && this.list.length > 0) {
                    this.defaults.stage = this.list[0].value
                    this.editEnvSession();
                }
            }
        }
        else if (this.checkComponent(url, 'emtcontrollersoftware')
            || this.checkComponent(url, 'tdccomponents')
            || this.checkComponent(url, 'equipment-activation')) {
            this.list = this.list.filter(x => x.value !== 3 && x.value !== 4)
            const currentStage = this.defaults.stage;
            var found = this.list.filter(x => x.value === this.defaults.stage).length
            if (found < 1) {
                if (this.defaults.isDomainExpertUser || this.defaults.isCountryBackendStableUser) this.defaults.stage = 0
                else if (this.defaults.isExternalUser) this.defaults.stage = 2
                else this.defaults.stage = 0
                if (currentStage !== this.defaults.stage && this.defaults)
                    this.editEnvSession()
            }
        }
        else if (window.location.href.indexOf('contract') < 0 && window.location.href.indexOf('file/2') < 0 && window.location.href.indexOf('backend-setup') < 0
            && window.location.href.indexOf('chairlift') < 0 && window.location.href.indexOf('file/8') < 0) {
            this.list = this.list.filter(x => x.value !== 3 && x.value !== 4)
            const currentStage = this.defaults.stage;
            var found = this.list.filter(x => x.value === currentStage).length
            if (found < 1) {
                if (this.defaults.isProdUserManagementPortalUser || this.defaults.isCountryBackendProdUser) this.defaults.stage = 1
                else if (this.defaults.isDomainExpertUser || this.defaults.isCountryBackendStableUser) this.defaults.stage = 0
                else if (this.defaults.isExternalUser) this.defaults.stage = 2
                else this.defaults.stage = 0
                if (currentStage !== this.defaults.stage && this.defaults) {
                    this.editEnvSession();
                }
            }
        }

        else if (this.checkComponent(url, 'contract') ||
            this.checkComponent(url, 'file/2') ||
            this.checkComponent(url, 'backend-setup')) {
            this.list = this.list.filter(x => x.value !== 4)
            if (this.defaults.isHomeUser && !this.defaults.isadminuser)
                this.list = this.list.filter(x => x.value !== 1)
            else if (!this.defaults.isadminuser)
                this.list = this.list.filter(x => x.value !== 3)
            const currentStage = this.defaults.stage;
            var found = this.list.filter(x => x.value === this.defaults.stage).length
            if (found < 1) {
                if (this.defaults.isDomainExpertUser || this.defaults.isCountryBackendStableUser) this.defaults.stage = 0
                else if (this.defaults.isExternalUser) this.defaults.stage = 2
                else this.defaults.stage = 0
                if (currentStage !== this.defaults.stage && this.defaults) {
                    this.editEnvSession();
                }
            }
        }

        else if (this.checkComponent(url, 'chairlift') ||
            this.checkComponent(url, 'file/8')) {
            this.list = this.list.filter(x => x.value !== 1 && x.value !== 4)
            const currentStage = this.defaults.stage;
            var found = this.list.filter(x => x.value === this.defaults.stage).length
            if (found < 1) {
                if (this.defaults.isDomainExpertUser || this.defaults.isCountryBackendStableUser) this.defaults.stage = 0
                else if (this.defaults.isExternalUser) this.defaults.stage = 2
                else this.defaults.stage = 0
                if (currentStage !== this.defaults.stage && this.defaults)
                    this.editEnvSession()
            }
        }      
    }

    private checkComponent(url: string, component: string) {
        return url.toLowerCase()
            .indexOf(component.toLowerCase()) > -1
    }
}