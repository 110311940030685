import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, ElementRef, ViewChild, Inject, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Injectable, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Params } from '@angular/router';
import { UMPCategory, Utils } from '../../../../../utils/utils';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { SaveService } from '../../../../../services/save.service';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { CreateDaworkspaceComponent } from '../../../group/daworkspace/createdaworkspace/createdaworkspace.component';
import { MatDialog } from '@angular/material/dialog';


interface DANode {
    groupId: string;
    groupName: string;
    subGroupsAllowed: boolean,
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: DANode;
    groups: DANode[]
}




@Component({
    selector: "daworkspace",
    templateUrl: './daworkspace.component.html',
    styleUrls: ['./daworkspace.component.less', '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class DAWorkspace implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined;
    userId = '';
    public TREE_DATA: DANode[] = [];/*** */

    public selectedNodes: string[] = [];
    public unSelectedNodes: string[] = [];

    public treeControl: NestedTreeControl<DANode>;
    public dataSource = new MatTreeNestedDataSource<DANode>();
    public rootNodes: any;
    public isExpanded = false;

    public searchString = '';
    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    isCallSucceed: number = 0;

    data: TreeManagement<UMPCategory> = {
        SelectedBranches: [],
        UnselectedBranches: [],
        UmpCategory: UMPCategory.DAnalyticsPowerBI
    }
    subscription: any;


    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        private saveService: SaveService,
        public defaults: DefaultValuesService,
        private dialogForm: MatDialog

    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });
        this.treeControl = new NestedTreeControl<DANode>((node) => node.groups)

    }

    ngOnInit() {
        this.subscription = this.defaults.dataAnalyticsTreeSourceChanges$.subscribe(async () => {
            this.resetAll();
        });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    resetAll() {
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.treeControl = new NestedTreeControl<DANode>((node) => node.groups)
        this.dataSource = new MatTreeNestedDataSource<DANode>();
        this.funcGetUserDataAnlytics();
    }
    private preselect() {
        this.unSelectedNodes = [] as string[];
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            if (this.selectedNodes.find(e => e === this.treeControl.dataNodes[i].groupId)) {
                this.itemToggle(true, this.treeControl.dataNodes[i]);
            }
            else
                this.unSelectedNodes.push(this.treeControl.dataNodes[i].groupId);
            if (this.treeControl.dataNodes[i].groups) {

                const alldecendents = this.treeControl.getDescendants(this.treeControl.dataNodes[i]);

                for (let j = 0; j < alldecendents.length; j++) {
                    if (this.selectedNodes.find(e => e === alldecendents[j].groupId)) {
                        this.itemToggle(true, alldecendents[j]);
                    }
                    else
                        this.unSelectedNodes.push(alldecendents[j].groupId);
                }
            }
        }

    }
    public hasChild = (_: number, node: DANode) =>
        !!node.groups && node.groups.length > 0;

    private setParent(node: DANode, parent: DANode | undefined) {
        node.parent = parent;
        if (node.groups) {
            node.groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    private checkAllParents(node: DANode) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.indeterminate = descendants.some((child) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    public itemToggle(checked: boolean, node: DANode, manualUpdate: boolean = false) {
        this.saveService.addListener(this)
        this.saveService.showSaveButton();
        node.selected = checked;
        if (node.groups && checked) {
            node.indeterminate = false;
            this.itemUnselect(node);
        }
        if (node.groups) {
            this.itemDisable(checked, node);
        }
        if (manualUpdate) {
            if (checked) {
                this.data.UnselectedBranches = this.data.UnselectedBranches.filter(x => x !== node.groupId)
                if (this.unSelectedNodes.find(e => e === node.groupId))
                    this.data.SelectedBranches.push(node.groupId);
            }
            else {
                this.data.SelectedBranches = this.data.SelectedBranches.filter(x => x !== node.groupId)
                if (this.selectedNodes.find(e => e === node.groupId))
                    this.data.UnselectedBranches.push(node.groupId);
            }
        }
        this.checkAllParents(node);
    }
    private itemUnselect(node: DANode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.selected ? this.data.UnselectedBranches.push(node.groupId) : null; node.indeterminate = false; node.selected = false });
    }

    private itemDisable(checked: boolean, node: DANode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.disabled = checked);
    }

    public onSave() {
        if (this.data.SelectedBranches.length > 0 || this.data.UnselectedBranches.length > 0) {
            this.spinnerOverlayService.setFalseskipInterceptor()
            Utils.httpPostTreeGroupsByType(
                this.http
                , this.baseUrl
                , this.data
                , this.userId
                , this
                , function (tthis: DAWorkspace, data: any) {
                    tthis.data.SelectedBranches = []
                    tthis.data.UnselectedBranches = []
                    tthis.spinnerOverlayService.setTrueskipInterceptor()
                    tthis.funcNotify();
                    tthis.funcGetUserDataAnlytics();
                    if (tthis.groupmgmtcallback)
                        tthis.groupmgmtcallback(data);
                }
            )
        }
    }

    public hideLeafNode(node: DANode): boolean {
        return new RegExp(this.searchString, 'i').test(node.groupName) === false;
    }


    public hideParentNode(node: DANode): boolean {
        if (
            !this.searchString ||
            node.groupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.groupName
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }
    funcGetUserDataAnlytics() {
        this.isExpanded = false;
        this.treeControl.dataNodes = [];
        this.TREE_DATA.splice(0, this.TREE_DATA.length);
        this.Tree_Response_State = -2;
        Utils.httpGetUserDataAnlytics(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , (tthis: DAWorkspace, data: any) => {
                tthis.TREE_DATA = data.treeView; ///******** */
                //tthis.rootNode = data.treeView[0].groupName;
                tthis.selectedNodes = data.excludedPersonae;
                tthis.rootNodes = structuredClone(data.treeView);
                tthis.smallerTree(tthis.rootNodes);



            });
    }
    smallerTree(smallTREE_DATA) {
        smallTREE_DATA.forEach((parent) => {
            parent.groups?.forEach((child) => {
                delete child.groups;
            });
        });
        //smallTREE_DATA=smallTREE_DATA.flatMap((node) => node.children);
        this.treeControl.dataNodes = smallTREE_DATA;
        this.dataSource.data = smallTREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        })
        this.preselect();

    }
    longTreeExpandAll() {
        this.treeControl.dataNodes = this.TREE_DATA;
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        }
        )
        this.preselect();
        this.isExpanded = true;
        this.treeControl.expandAll()
    }

    expandTriggered(name) {
        this.treeControl.dataNodes = this.TREE_DATA;
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        }
        )
        this.preselect();
        this.expandNodeByName(name);
        this.isExpanded = true;

    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('DataAnalytics & PowerBI request sent successfully !', 'Data Analytics and PowerBI Workspaces!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('DataAnalytics & PowerBI request not sent successfully !', 'Data Analytics and PowerBI Workspaces!');
        }
    }
    expandNodeByName(name: string) {
        const node = this.findNodeByName(this.treeControl.dataNodes, name);
        if (node) {
            this.treeControl.expand(node);
        }
    }

    findNodeByName(nodes: DANode[], name: string): DANode | undefined {
        for (const node of nodes) {
            if (node.groupName === name) {
                return node;
            }
            //if (node.groups) {
            //    const foundNode = this.findNodeByName(node.groups, name);
            //    if (foundNode) {
            //        return foundNode;
            //    }
            //}
        }
        return undefined;
    }

    openPowerbiWorkspacePopup() {
        const dialog = this.dialogForm.open(CreateDaworkspaceComponent, {
            panelClass: ["custom-mat-dialog_v2"],
            disableClose: true,
            width: '550px',
            data: {}
        })
    }
}