import { Component } from '@angular/core';

@Component({
    selector: "eoxniops-powerbi-report",
    templateUrl: './eoxniops-powerbi-report.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})

export class EoxNiOpsPowerBiReportComponent {

    ReportType: string = "";

    constructor(
    ) {

    }

}
