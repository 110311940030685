import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { Utils } from '../../utils/utils';
import { lengthValidator, rangeValidator, isValidEncoding } from './validators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
@Component({
  selector: 'dialerproperties-list',
  templateUrl: './dialerproperties-list.component.html',
    styleUrls: ['./dialerproperties-list.component.less', '../../style/new-generic-styles.component.less'],
      encapsulation: ViewEncapsulation.None

})
export class DialerpropertiesListComponent implements OnInit {

    displayedColumns: string[] = ['Parameter', 'DefaultValues', 'Condition', 'Value', 'Action'];
    displayedColumns2: string[] = ['Parameter', 'DefaultValues', 'Condition', 'Value'];
    dataSource = new MatTableDataSource<any>();
    responseDialerPropTable: DialerPropeties[] = [];
    response: any;
    isLoading = true;
  

    pageNumber: number = 1;
    VOForm!: FormGroup;
    isEditableNew: boolean = true;
    Response_State: number = 1; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    regCode: undefined | string = undefined;
    dialerCode: undefined | string = undefined;

    private destroy$ = new Subject<void>(); 

    constructor(
        private fb: FormBuilder,
        public defaults: DefaultValuesService,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private routeService: Router,
        private spinnerOverlayService: SpinnerOverlayService,
        private toastrService: ToastrService,
        private _formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });
        //this.defaults.languageMessageChanges$.subscribe(() => {
        //    this.regCode = this.dialerCode,
        //    (this.defaults.tenant && this.dialerCode && this.regCode) ? (this.funcGetDialerProperties()) : this.resetData()
        //})
        this.defaults.RegCodeChanged$.pipe(takeUntil(this.destroy$)).subscribe((regCode) => { this.setup(regCode) })
    }

    setup(regCode) {
        this.regCode = regCode;
        this.dialerCode = this.defaults.dialerCode;
        (this.defaults.tenant && this.dialerCode && this.regCode) ? (this.funcGetDialerProperties()) : this.resetData();
    }

    ngAfterViewInit() {       
    }
    resetData() {

        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });
        this.VOForm.reset()
        this.dataSource.data = []
    }

 



    // this function will enabled the select field for editd
    EditSVO(VOFormElement, i) {
        VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
    }


    // On click of correct button in table (after click on reset) this method will call *****
    ResetSVO(index) {
        var defaultObjectValues = this.responseDialerPropTable.find(sft => sft.parameter == this.VOForm.value.VORows[index]?.Parameter)
        this.UpdateFormFeature(defaultObjectValues, index)
        this.VOForm.value.VORows[index].isEditable = true;
    }

 



    funcGetDialerProperties() {
        this.resetData()
        this.Response_State = -2;
        this.spinnerOverlayService.setTrueskipInterceptor();
        var queryString = this.defaults.tenant + '[]' + this.regCode + '[]' + this.dialerCode;
        Utils.httpGetDialerProperties(
            this.http
            , this.baseUrl
            , queryString
            , this
            , function (tthis: DialerpropertiesListComponent, response: any) {
                tthis.response= response
                tthis.responseDialerPropTable = response.configuration;
                if (tthis.responseDialerPropTable?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;
                }
                tthis.funcMaptoForm();
            }
        )
        this.spinnerOverlayService.setFalseskipInterceptor();
    }


    funcMaptoForm() {
        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });

        this.VOForm = this.fb.group({
            VORows: this.fb.array(this.responseDialerPropTable.map(val => this.fb.group({
                Parameter: new FormControl(val.parameter),
                Value: new FormControl(val.value, this.funcValidatorSelect(val)),
                DefaultValue: new FormControl(val.defaultValue),
                Charset: new FormControl(val.charset),
                action: new FormControl('existingRecord'),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.isLoading = false;
        this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);

    }
    funcValidatorSelect(elem:any) {
        return (control: AbstractControl): ValidationErrors | null => {

            //if (elem.value == "null") return null 
            if (elem.optionsDisplayType === 'string') {
                if (isValidEncoding(elem.charset)(control)) { return isValidEncoding(elem.charset)(control) }
                return lengthValidator(elem.min, elem.max)(control);
                
            }
            else if (elem.optionsDisplayType === 'int') {
                return rangeValidator(elem.min, elem.max)(control);
            }

            return null;
        };
    }
    



    UpdateFormFeature(unModedObj, index) {

        var temp = this.VOForm.value.VORows;
        temp[index].Value = unModedObj.value;

        this.VOForm.get('VORows')?.setValue(temp);

    }

    onSave() {
        var modifiedRows = this.VOForm.get('VORows')?.value.filter(row => !row.isEditable);

        if (modifiedRows.length < 1) return 
        var temp = 1;
        const config: dialerConfiguration[] = modifiedRows.map((obj) => {
            return {
                parameter: obj.Parameter.toString(),
                value: obj.Value.toString(),
            };
        });
        var req: updateDialerPropertiesReqVM = {
            tenant: this.response.tenant,
            dialerType: this.response.dialerType,
            regulatoryCode: this.response.regulatoryCode,
            configuration: config
        }

        Utils.httpPostDialerProperties(this.http
            , this.baseUrl
            , req
            , this
            , function (tthis: DialerpropertiesListComponent, response: any) {
                tthis.responseHandler(response)
                tthis.funcGetDialerProperties();
            })
    }

    responseHandler(response) {
        response === true ?
            this.toastrService.success('Dialer Properties Updated successfully', 'Update Dialer Property!') :
            this.toastrService.error(response, 'Update Dialer Property!')
    }

    isFormControlValueInvalid(index) {
        const rowArray = this.VOForm.get('VORows') as FormArray;
        return rowArray.at(index).get("Value")?.invalid;
    }
    invalidFormControlError(index) {
        const rowArray = this.VOForm.get('VORows') as FormArray;
        var tem = rowArray.at(index).get("Value")?.errors

        for (let key in tem) {
            return (tem[key])
            // Use `key` and `value`
        }

    }

    shouldUseDisplayedColumns(): string[] {
        return this.defaults.isControllerOwner ? this.displayedColumns : this.displayedColumns2;
    }


    ngOnDestroy() {   
        this.destroy$.next();
        this.destroy$.complete();
    }
    
}
