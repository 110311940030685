import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../services/dialog.service";

@Component({
    selector: "service-ticket-list",
    templateUrl: './service-ticket-list.component.html',
    styleUrls: ['./service-ticket-list.component.less', '../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ServiceTicketListComponent implements OnInit {
    @Input('list') list: ServiceTicket[] = [];
    @Input('showLocalFilter') showLocalFilter: boolean = false;

    columnsToDisplay = ['arrow', 'index', 'status', 'ServiceRecordId', 'TicketId', 'UnitId', 'TicketStatus', 'TechnicianName', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['Source', 'ReportedDateTime', 'ReportedDateTimeLocal', 'StartDateTime', 'StartDateTimeLocal', 'EndDateTime', 'EndDateTimeLocal',
        'TicketDescriptionLocale', 'WorkDescriptionLocale', 'IsCompleted', 'TransactionId', 'TechnicianId', 'LanguageCultureName', 'IsTraveltime', 'HoursSpent', 'TicketStatus',
        'IsVandalism', 'FaultCode', 'FaultDescriptionLocale', 'CauseCode', 'CauseDescriptionLocale', 'ResolutionCode', 'ResolutionDescriptionLocale', 'IsUnscheduled',
        'IsCallback', 'Subsystem', 'IsDoorCallback', 'Category', 'CategoryLocale', 'SubCategory', 'SubCategoryLocale', 'FaultCodeLocale',
        'TicketStatusLocale', 'LocationCode', 'LocationCodeLocale', 'LocationDescriptionLocale', 'CauseCodeLocale', 'ResolutionCodeLocale',
        'TicketPriority', 'DocumentUri', 'ComponentCode', 'ComponentCodeLocale', 'ComponentDescriptionLocale', 'ExpenditureType',
        'ExpenditureTypeLocale', 'IsDeleted', 'MaxreferenceId', 'MaxreferenceAlertId', 'TicketCreatedDateTime', 'TicketCreatedDateTimeLocal',
        'TicketDispatchedDateTime', 'TicketDispatchedDateTimeLocal', 'IsCanceled', 'CancelReason', 'CancelReasonLocale', 'IsShutdown',
        'ShutdownReasonLocale', 'ShutdownStartDate', 'ShutdownStartDateLocal', 'ShutdownEndDate', 'ShutdownEndDateLocal', 'ShutdownType',
        'UpdatedDateTime', 'UpdatedDateTimeLocal', 'RequesterType', 'RequesterLocale', 'IsBillable', 'IsBreakDown', 'IsApproved', 'IsOvertime',
        'MaxProposedResolutionItemId', 'CustomerLabel', 'CustomerPortalHide', 'EstimatedTimeArrival', 'SladueIn', 'ResponseTime',
        'StoppedTime', 'NonFault', 'Postponed', 'EstimatedInServiceDate', 'IsChargeable', 'ChargedAmount', 'ChargedAmountCurrency', 'IsSwp',
        'IsSwpOnArrival', 'SwpReleasedDateTime', 'SwpRescueType', 'IsStopped', 'CallerName', 'CallerEmail', 'CallerMobile', 'IsCallNotToCc',
        'IsEtarequested', 'OriginalEtafrom', 'OriginalEtato', 'UpdatedEtafrom', 'UpdatedEtato', 'IsEtaprovidedToCustomer', 'IsPostponed',
        'PostponedDate', 'IsPostponedCustomerRequest', 'IsPhonedTested', 'IsRepairOpportunity', 'ClientComments', 'IsCustomerSigned',
        'CustomerNotSignedReason', 'IsPassengerInjured', 'IsEmployeeInjured', 'IncidentType', 'IncidentComments', 'CallConfirmedTime',
        'TravelStartTime', 'TravelEndTime', 'TicketVersionId', 'ServiceDocketNumber', 'PartitionDate',

        'CategoryLocaleKey', 'SourceSystemId'
    ];

    booleanColumns = ['IsCompleted', 'IsTraveltime', 'IsVandalism', 'IsUnscheduled', 'IsCallback', 'IsDoorCallback', 'IsDeleted', 'IsCanceled', 'IsShutdown', 'IsBillable', 'IsBreakDown',
        'IsApproved', 'IsOvertime', 'CustomerPortalHide', 'NonFault', 'Postponed', 'IsChargeable', 'IsSwp', 'IsSwpOnArrival', 'IsStopped', 'IsCallNotToCc',
        'IsEtarequested', 'IsEtaprovidedToCustomer', 'IsPostponed', 'IsPostponedCustomerRequest', 'IsPhonedTested', 'IsRepairOpportunity', 'IsCustomerSigned', 'IsPassengerInjured',
        'IsEmployeeInjured']

    dateColumns = ['StartDateTime', 'EndDateTime', '_ChangedAtUtc', 'ReportedDateTime', 'ReportedDateTimeLocal', 'StartDateTimeLocal', 'EndDateTimeLocal',
        'TicketCreatedDateTime', 'TicketCreatedDateTimeLocal', 'TicketDispatchedDateTime', 'TicketDispatchedDateTimeLocal',
        'ShutdownStartDate', 'ShutdownStartDateLocal', 'ShutdownEndDate', 'ShutdownEndDateLocal',
        'UpdatedDateTime', 'UpdatedDateTimeLocal', 'EstimatedTimeArrival', 'SladueIn', 'EstimatedInServiceDate', 'SwpReleasedDateTime',
        'OriginalEtafrom', 'OriginalEtato', 'UpdatedEtafrom', 'UpdatedEtato', 'PostponedDate', 'CallConfirmedTime',
        'TravelStartTime', 'TravelEndTime', 'PartitionDate']
    numberColumns = ['HoursSpent', 'ResponseTime', 'StoppedTime', 'ChargedAmount']

    notEditableColumns = ['ServiceRecordId', 'TicketId', 'UnitId', 'TransactionId', '_ChangedAtUtc']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<ServiceTicket>();
    selected: ServiceTicket | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: ServiceTicket; } = {};

    filter: string = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimaryElevator = '-1'

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: ServiceTicket, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    const fields: string[] = []
                    fields.push(data.ServiceRecordId ? data.ServiceRecordId : '')
                    fields.push(data.TicketId ? data.TicketId : '')
                    fields.push(data.UnitId ? data.UnitId : '')
                    fields.push(data.TicketStatus ? data.TicketStatus : '')
                    fields.push(data.TechnicianName ? data.TechnicianName : '')
                    return matchFilter.push(fields.some(item => item.includes(filter)));
                });
            }
            else {
                let result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }
    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'status', 'ServiceRecordId', 'TicketId', 'UnitId', 'TicketStatus', 'TechnicianName', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'status', 'ServiceRecordId', 'TicketId', 'UnitId', 'TicketStatus', 'TechnicianName', '_ChangedAtUtc'];
    }

    getInput(data: ServiceTicket, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    isDeleted(element: ServiceTicket) {
        return element.IsDeleted
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    pushPopElement(element: ServiceTicket) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: ServiceTicket) {
        return this.expandedElements.has(element);
    }

    onExpand(element: ServiceTicket) {
        Utils.httpGetDetailedServiceTicket(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: ServiceTicketDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(serviceTicket: ServiceTicket) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(serviceTicket)] = { ...serviceTicket };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(serviceTicket)
    }

    onRowEditCancel(serviceTicket: ServiceTicket) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.ServiceRecordId === serviceTicket.ServiceRecordId && x.UnitId === serviceTicket.UnitId)] = this.clonedList[this.getDictionaryHash(serviceTicket)];
        delete this.clonedList[this.getDictionaryHash(serviceTicket)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(serviceTicket: ServiceTicket, property: string) {
        return this.clonedList[this.getDictionaryHash(serviceTicket)] &&
            (this.notEditableColumns.indexOf(property) < 0)
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.ServiceRecordId === this.clonedList[i].ServiceRecordId && x.UnitId === this.clonedList[i].UnitId && this.clonedList[i].TicketId === x.TicketId)
            var item = { ...this.dataSource.data[index].Details[0], ...this.dataSource.data[index] }
            item.IsCompleted = item.IsCompleted;
            updatedList.push(item)
        }
        Utils.httpUpdateServiceTickets(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , updatedList
            , this
            , function (tthis: any, list: ServiceTicket[]) {
                tthis.clonedList = {}
                tthis.saveService.showSaveButton(false)
                tthis.expandedElements.clear();
                tthis.table.renderRows();
            }
            , function (tthis: ServiceTicketListComponent, message: string) {
                tthis.saveService.addListener(tthis)
                tthis.saveService.showSaveButton(true)
                tthis.dialog.showErrorDialog(message);
            }
        );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getDictionaryHash(serviceTicket: ServiceTicket) {
        return serviceTicket.UnitId + (serviceTicket.ServiceRecordId ?? '') + serviceTicket.TicketId
    }

}
export interface SearchItem {
    name: string;
}