 <!--The Heading sections (Heading + Serach )-->
<div class="addGrpDialogue">
    <mat-toolbar class="header heading-background">
        <!--<div class="row heading-background mt-5">-->

        <div class="col-lg-6">
            <h5>
                Add {{data.title}}
            </h5>
        </div>
        <div class="col-lg-6 search-container">

            <div (change)="updateRolesResults()" (keyup)="updateRolesResults()">
                <input class="form-control" type="text" placeholder="Search" [(ngModel)]="searchString">
            </div>

        </div>
    </mat-toolbar>


    <!--The Table-->
    <div class="custom_small_panelbody_fix" *ngIf="!IsDirIndirComponent">

        <mat-selection-list [formControl]="formControlGroups">
            <mat-list-option *ngFor="let item of filteredSearchResults" [value]="item.id" checkboxPosition="before" [style.display]=" item.hidden ? 'none' : 'block'">
                {{item.persona}}
                <span *ngIf="hasAdditionalCosts(item.persona) === 1" style="color:greenyellow !important">$$</span>
                <span *ngIf="hasAdditionalCosts(item.persona) === 0" style="color:greenyellow !important">$</span>
            </mat-list-option>
        </mat-selection-list>

    </div>

    <div class="custom_small_panelbody_fix" *ngIf="IsDirIndirComponent">
        <h5 style="padding:0 16px" *ngIf="userPrimaryRole">
            User already assigned to {{userPrimaryRole.persona}}. Role must be removed to assign another Primary Role
            <mat-icon class="adgroup-custom-tooltip" matTooltipClass='adgroup-custom-tooltip' matTooltip="Primary Roles: {{primaryRoles}}">info</mat-icon>
        </h5>
        <mat-selection-list [formControl]="formControlGroups" [multiple]="false">
            <mat-list-option *ngFor="let item of filteredSearchResults" [value]="item.id" checkboxPosition="before"
                             [disabled]="userPrimaryRole && item.isPrimaryRole"
                             [style.display]=" item.hidden ? 'none' : 'block'">
                {{item.persona}}
            </mat-list-option>
        </mat-selection-list>

    </div>


    <div class="panel-body">

        <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>
        <button mat-raised-button type="submit" (click)="addButtonHandler(formControlGroups.value)" class="btn button-color-fix pull-right ">
            Save
            <mat-icon class="icon-color-fix">save</mat-icon>
        </button>

    </div>
</div>

