import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryLeverDialog } from '../history-lever-dialog/history-lever-dialog.component';

@Component({
    selector: 'assessment-list',
    templateUrl: './assessment-list.component.html',
    styleUrls: ['./assessment-list.component.less',
        '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class AssessmentListComponent implements OnInit {

    @Input('childData') childData: any[] = [];
    @Input('stages') stages: ExcellenceStage[] = [];
    @Input('contacts') contacts: BranchContact[] = [];
    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;

    updatedItems: number[] = []
    selectedFactorValues: { [leverId: number]: { values: number[], avg: number | undefined } } = {};

    columnsToDisplay = ['Function', 'LineOfBusiness', 'ExcellenceLever', 'Factors', 'KPIs', 'Action', 'Notes', 'BestPractices', 'actions'];
    subcolumnsToDisplay = ['Factor','Description', 'Stage'];
    subcolumnsKpisToDisplay = ['AccountId', 'GF', 'Description'];

    public dataSource = new MatTableDataSource<any>();

    expandedElements = new Set();
    expandedKpis = new Set();
    isOpenTextAreaOverlay = false;

    dialogRef: any;
    constructor(
        private dialogForm: MatDialog
    ) { }

    ngOnInit(): void {
    }

    initializeSelectedFactorValues() {
        this.childData.forEach(lever => {
            const leverId = lever.WaveExcellenceLeverConfigId;
            this.selectedFactorValues[leverId] = {
                values: lever.Factors.map(factor => Number(factor.ExcellenceStage)),
                avg: 0
            };
            this.computeAvgFactor(leverId);
        });
    }

    clearSelectedFactorValues() {
        this.selectedFactorValues = {} as { [leverId: number]: { values: number[], avg: number | undefined } };  
    }

    ngOnChanges(changes: any) {
        if (changes.childData) {
            this.dataSource.data = this.childData;

            if (Object.keys(this.selectedFactorValues).length === 0) {
                this.initializeSelectedFactorValues();
            }
        }
    }

    pushPopElement(element: any, expandKpis: boolean) {
        if (!expandKpis) {
            if (this.expandedElements.has(element)) {
                this.expandedElements.delete(element);
            }
            else {
                this.expandedElements.add(element);
            }
        }
        else {
            if (this.expandedKpis.has(element)) {
                this.expandedKpis.delete(element);
            }
            else {
                this.expandedKpis.add(element);
            }
        }
    }
    checkExpanded(element: any, expandKpis: boolean) {
        if (!expandKpis)
            return this.expandedElements.has(element);
        else
            return this.expandedKpis.has(element);
    }
    onDetail($event: any, lever: any) {
        $event.preventDefault();
        this.dialogRef = this.dialogForm.open(HistoryLeverDialog, {
            height: '525px',
            width: '1000px',
            data: { lever: lever }
        });
        this.dialogRef.afterClosed().subscribe(() => {
        });
    }

    onChange(waveExcellenceLeverConfigId: number) {
        if (!this.updatedItems.includes(waveExcellenceLeverConfigId))
            this.updatedItems.push(waveExcellenceLeverConfigId)
    }

    onTextFieldChange(comment: string, textAreaId: string, lever: any) {
        if (lever) {
            let type = textAreaId.split('_')[0];
            switch (type) {
                case 'Action':
                    lever.Action = comment;
                    break;
                case 'Notes':
                    lever.Notes = comment;
                    break;
                case 'BestPractices':
                    lever.BestPractices = comment;
                    break;
            }
            this.onChange(lever.WaveExcellenceLeverConfigId)
        }
    }

    updateAvgFactor(waveExcellenceLeverConfigId: number, factorIndex: number, value: number) {
        if (!this.selectedFactorValues[waveExcellenceLeverConfigId]) {
            this.selectedFactorValues[waveExcellenceLeverConfigId].values = [];
        }
        this.selectedFactorValues[waveExcellenceLeverConfigId].values[factorIndex] = value;
        this.computeAvgFactor(waveExcellenceLeverConfigId);
    }

    computeAvgFactor(waveExcellenceLeverConfigId: number) {
        const values = this.selectedFactorValues[waveExcellenceLeverConfigId].values || [];
        let total : number = 0;
        let count : number = 0;

        values.forEach(value => {
            const numValue = Number(value);
            if (!isNaN(numValue) && numValue > 0) {
                total += numValue;
                count++;
            }
        });

        const average = count > 0 ? total / count : 0;
        const roundedAverage = Math.ceil(average);
        this.selectedFactorValues[waveExcellenceLeverConfigId].avg = roundedAverage > 0 ? roundedAverage : undefined;
    }
}
