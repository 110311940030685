import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APICallStatus, Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { FilterPopupComponent } from "../../shared-components/filterpopup/filterpopup.component";
import { Router } from "@angular/router";
import { DialogService } from "../../../../services/dialog.service";
import { CustomDialogService } from "../../../../services/customdialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { ToastrService } from "ngx-toastr";
import { MarketSheetListComponent, SearchItem } from "./market-sheet-list.component";

@Component({
    selector: "market-sheet",
    templateUrl: './market-sheet.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})

export class MarketSheetComponent implements OnInit, OnDestroy {


    @ViewChild(MarketSheetListComponent, { static: true }) listComponent: MarketSheetListComponent = {} as MarketSheetListComponent;
    isReleaseActive: boolean = false;
    releaseButtonText: string = 'Initiate Release';

    isRevokeActive: boolean = false;
    revokeButtonText: string = 'Initiate Revoke';

    isEditModeEnabled = false;
    isShowAllEnabled = true;
    isExcelModeEnabled = false;

    subscriptionManagementId: any;
    subscriptionFiscalYear: any;
    subscriptions: any[] = [];

    list: KpiManagement[] = [];

    // Filter section
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    filter: string = ''
    selectorFilter = '0';
    selectorKpiUnitFilter = '0';
    inclusiveMode = false;
    isAllLoaded = false;
    waitHeader = `   -  **Please, wait until all breakdowns are properly loaded**`;
    columnsToDisplay2 = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'FY', 'YoYFY', 'ASPFY', '%FY', 'FY1', 'YoYFY1', 'ASPFY1', '%FY1', 'FY2', 'YoYFY2', 'ASPFY2', '%FY2', 'FY3', 'YoYFY3', 'ASPFY3', '%FY3'];

    yearlyKeys = ['PYBUD', 'FY1BUD', 'FY2BUD', 'FY3BUD'];
    showReleaseLabel = false;

    title: string | undefined;
    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    valueMarketComparisonResults = {}
    unitMarketComparisonResults = {}
    unitTKEComparisonResults = {}
    valueTKEComparisonResults = {}
    isComparisonMismatch: boolean = true;

    currentFY: string | undefined;
    FY1: string | undefined;
    FY2: string | undefined;
    FY3: string | undefined;

    constructor(
        public defaults: DefaultValuesService
        , private http: HttpClient
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private router: Router
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService

    ) {
        this.title = `Market Sheet (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;
        this.dialog.tthis = this
    }


    ngOnInit() {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() => { this.loadInformation() });
        if (this.defaults.managementids.length !== 0) {
            this.loadInformation();
        }
    }

    ngOnDestroy() {
        this.subscriptionManagementId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    //////////////////
    //OnClick Methods

    onClickSave() {

        var wasAnyUpdate = this.listComponent.updatedItems.length > 0

        this.listComponent.updatedItems = this.listComponent.updatedItems.filter(x => x.KpiValue !== 'NaN')

        if (this.listComponent.updatedItems.length > 0) {

            Utils.httpUpdateMarketQueryInformation(
                this.http
                , this.baseUrl
                , 'MARKET'
                , this.listComponent.updatedItems
                , this
                , function (tthis: MarketSheetComponent) {
                    tthis.toastrService.success('Market sheet values were updated successfully!');
                    tthis.loadInformation()
                }
                , function (tthis: MarketSheetComponent, error: any) {
                    tthis.dialog.showErrorDialog(error);
                }
            )
        }
        else {
            this.isEditModeEnabled = false;
            if (wasAnyUpdate)
                this.loadInformation()
        }
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.listComponent.updatedItems.length > 0) {
                this.loadInformation()
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    onClickShowAllButton() {
        if (this.isShowAllEnabled === false) {
            this.isShowAllEnabled = true
        }
        else {
            this.isShowAllEnabled = false
        }
        this.updatefilter()
    }

    onClickCancelButton() {
        this.isEditModeEnabled = false;
    }

    onClickReleaseMode() {

        var selectedRows = this.listComponent?.getSelectionList();
        if (!selectedRows)
            selectedRows = []

        this.customDialog.openConfirm({
            title: "Release values",
            message: `Do you want to release all populated values selected? Please note that you cannot edit any values after the release!.`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpReleaseMarketQueryInfo(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , true
                    , this
                    , function (tthis: MarketSheetComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Market sheet values were released successfully!');
                        tthis.toggleRelease();
                    }
                );
            }
        })
    }
    onClickRevoke() {
        var selectedRows = this.listComponent?.getSelectionList();
        if (!selectedRows)
            selectedRows = []
        this.customDialog.openConfirm({
            title: "Revoke values",
            message: `Do you want to revoke all populated values for selected period?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpRevokeMarketQueryInfo(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , false
                    , this
                    , function (tthis: MarketSheetComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Market Sheet values were revoked successfully!');
                        tthis.toggleRevoke();
                    }
                );
            }
        })
    }

    // Open filter Popup
    openFilterPopup() {
        const dialog = this.dialogForm.open(FilterPopupComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog'],
            disableClose: true,
            data: { "selectorFilter": this.selectorFilter, "inclusiveMode": this.inclusiveMode }
        })
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.selectorFilter = result.selectorFilter;
                this.inclusiveMode = result.inclusiveMode;
                this.updatefilter()
            }
        })
    }


    onSelectorChange($event: string) {
        this.selectorFilter = $event
    }

    onClickExcel() {
        this.isExcelModeEnabled = true;
        this.isEditModeEnabled = false;
    }

    onClickExitExcel() {
        this.isExcelModeEnabled = false;
    }

    onClickDownload() {
        Utils.httpDownloadMarketQuery(
            this.http,
            this.baseUrl,
            `MarketSheet`,
            'MARKET');
    }

    onClickUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadMarketSheet()]);
    }

    private loadInformation() {
        this.getFiscalYearsByKey()
        this.tableResponseState = APICallStatus.Waiting4Response;
        this.isEditModeEnabled = this.isReleaseActive = this.isRevokeActive = false;
        this.updateReleaseButtonText()
        this.updateRevokeButtonText()
        this.showReleaseLabel = false;
        this.title = `Market Sheet (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;
        this.subscriptions.forEach(x => x.unsubscribe())
        this.isAllLoaded = false;
        Utils.httpGetMarketQueryInformation(
            this.http
            , this.baseUrl
            , 'MARKET'
            , this
            , function (tthis: MarketSheetComponent, result: KpiManagement[]) {
                if (result) {
                    setTimeout(() => {
                        result = result.filter(x => x.KpiDetail.length > 0);                       
                        result.forEach(x => {
                            if (!x.subtable) {
                                tthis.initKpiHistoryLogDialogTags(x);
                                let showActions = x.KpiDetail.some(x => x.KpiId != undefined);
                                let columns = Object.assign([], tthis.columnsToDisplay2);
                                if (showActions)                                  
                                    columns.push('actions');                                                                                          
                                const row = x.KpiDetail[0]
                                let maxBreakdown = ''
                                if (row.COL4 !== '') {
                                    maxBreakdown = 'COL4'
                                }
                                if (row.COL4 === '') {
                                    columns.splice(columns.indexOf('COL4'), 1)
                                    maxBreakdown = 'COL3'
                                }
                                if (row.COL3 === '') {
                                    columns.splice(columns.indexOf('COL3'), 1)
                                    maxBreakdown = 'COL2'
                                }
                                if (row.COL2 === '') {
                                    columns.splice(columns.indexOf('COL2'), 1)
                                    maxBreakdown = 'COL1'
                                }
                                if (row.COL1 === '') {
                                    columns.splice(columns.indexOf('COL1'), 1)
                                    maxBreakdown = 'COL0'
                                }

                                x.columns = columns
                                x.maxBreakdown = maxBreakdown
                                tthis.showReleaseLabel = tthis.showReleaseLabel || x.ApprovalStatus === 'RELEASED'
                            }
                        })
                        tthis.list = result
                        tthis.checkCompetitorsTechnologyValues();
                        tthis.isAllLoaded = true;
                        tthis.tableResponseState = APICallStatus.ResponseDataOk;
                        tthis.updatefilter();
                    }, 50)
                }
                else {
                    tthis.dialog.extraClasses = ['marginTop_150'];
                    tthis.dialog.showErrorDialog({ 'error': { 'message': 'Please configure the user to have permission to query market' } });
                    tthis.list = [];
                    tthis.tableResponseState = APICallStatus.ResponseEmptyOk;
                }
            }
            , function (tthis: MarketSheetComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
                tthis.tableResponseState = APICallStatus.ResponseDataOk;
                tthis.list = [];
            }
        );
    }

    ////////////////
    //Filter section
    auxFilterPredicate(filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) {
        const matchFilter: any[] = [];
        const filterArray = filters.split('+').filter(x => x)
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items

        if (filterArray.length > 0) {
            filterArray.forEach((filter) => {
                let result = false;

                if (this.selectorKpiUnitFilter == '0')
                    result = data.KpiUnit.toLowerCase() === 'values' || data.KpiUnit.toLowerCase() === 'values lc'

                else
                    result = data.KpiUnit.toLowerCase() === 'units'

                if (parentLevel === true) {
                    const kpiname = data.KpiName ? data.KpiName : ''
                    const id = data.AccountID ? data.AccountID : ''
                    result = result && (kpiname.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        id.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
                }
                else {
                    if (this.selectorFilter == '1') {
                        const granularity = data.Granularity ? data.Granularity : ''
                        result = result && (granularity.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
                    }
                    if (!showAllEnabled) {
                        result = result &&
                            (Utils.IsValidNumber(data.PYBUD) ||
                                Utils.IsValidNumber(data.FY1BUD) ||
                                Utils.IsValidNumber(data.FY2BUD) ||
                                Utils.IsValidNumber(data.FY3BUD))
                    }
                }
                matchFilter.push(result);
            });
        }
        else {
            let result = true
            if (parentLevel === true) {
                result = true
            }
            else {
                if (!showAllEnabled) {
                    result = result &&
                        (Utils.IsValidNumber(data.PYBUD) ||
                            Utils.IsValidNumber(data.FY1BUD) ||
                            Utils.IsValidNumber(data.FY2BUD) ||
                            Utils.IsValidNumber(data.FY3BUD))
                }
            }
            if (this.selectorKpiUnitFilter == '0')
                result = result && (data.KpiUnit.toLowerCase() === 'values' || data.KpiUnit.toLowerCase() === 'values lc')
            else if (this.selectorKpiUnitFilter == '1')
                result = result && data.KpiUnit.toLowerCase() === 'units'
            matchFilter.push(result);
        }
        if (!this.inclusiveMode)
            return matchFilter.some(Boolean);
        else
            return matchFilter.every(Boolean);

    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.filter = ''
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
                .concat(`+${(this.selectorKpiUnitFilter === '1') ? 'FilterUnits' : 'FilterValues'}`)
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
                .concat(`+${(this.selectorKpiUnitFilter === '1') ? 'FilterUnits' : 'FilterValues'}`)
            this.filter = filterString
        }
    }

    onBack() { }

    updateSelectorKpiUnit(value) {
        this.selectorKpiUnitFilter = value
        this.updatefilter()
    }
    get isLoading(): boolean {
        return this.tableResponseState === APICallStatus.Waiting4Response;
    }

    /////////
    // UI Logic
    public toggleRelease(manual: boolean = false) {
        this.isReleaseActive = !this.isReleaseActive;
        if (!this.isComparisonMismatch && this.isReleaseActive && manual) {
            this.dialog.showSuccessDialog('',
                'Comparison between following Technology and Competitor not matching!',
                [...this.getKeysWithZeroValue("Market Values", this.valueMarketComparisonResults),
                ...this.getKeysWithZeroValue("Market Units", this.unitMarketComparisonResults),
                ...this.getKeysWithZeroValue("TKE Values", this.valueTKEComparisonResults),
                ...this.getKeysWithZeroValue("TKE Units", this.unitTKEComparisonResults)])
        }
        this.updateReleaseButtonText()
    }

    updateReleaseButtonText() {
        this.releaseButtonText = this.isReleaseActive ? 'Abort Release' : 'Initiate Release', this.listComponent.clearSelectionList()
    }

    public toggleRevoke() {
        this.isRevokeActive = !this.isRevokeActive;
        this.updateRevokeButtonText()
    }

    updateRevokeButtonText() {
        this.revokeButtonText = this.isRevokeActive ? 'Abort Revoke' : 'Initiate Revoke', this.listComponent.clearSelectionList()
    }

    canUserRevoke() {
        return this.defaults.isMersyRevokerMarketQueryUser
            || this.defaults.isMersyOwnerMarketQueryUser
            || this.defaults.isMersyAdminMarketQueryUser
    }

    canUserRelease() {
        return this.canUserRevoke()
            || this.defaults.isMersyReporterMarketQueryUser
    }

    canUserUpdate() {
        return this.canUserRelease()
            || this.defaults.isMersyDelegateMarketQueryUser
    }

    checkCompetitorsTechnologyValues() {

        this.isComparisonMismatch = true;

        const units = this.list.filter((item: any) => item.KpiUnit.toLowerCase() === "units");
        const values = this.list.filter((item: any) => item.KpiUnit.toLowerCase() === "values");

        const unitMarketSums = this.calculateSums(units, true);
        const unitTKESums = this.calculateSums(units, false);
        const valueMarketSums = this.calculateSums(values, true);
        const valueTKESums = this.calculateSums(values, false);

        for (const key of Object.keys(unitMarketSums[0]['values'] as any)) {
            this.unitMarketComparisonResults[key] =
                (unitMarketSums[0]['values'] as any)[key] as any === (unitMarketSums[1]['values'] as any)[key] as any;

            this.isComparisonMismatch = this.unitMarketComparisonResults[key] && this.isComparisonMismatch
        }

        for (const key of Object.keys(valueMarketSums[0]['values'] as any)) {
            this.valueMarketComparisonResults[key] =
                (valueMarketSums[0]['values'] as any)[key] as any === (valueMarketSums[1]['values'] as any)[key] as any;
            this.isComparisonMismatch = this.valueMarketComparisonResults[key] && this.isComparisonMismatch

        }
        for (const key of Object.keys(unitTKESums[0]['values'] as any)) {
            this.unitTKEComparisonResults[key] =
                (unitTKESums[0]['values'] as any)[key] as any === (unitTKESums[1]['values'] as any)[key] as any;
            this.isComparisonMismatch = this.unitTKEComparisonResults[key] && this.isComparisonMismatch

        }

        for (const key of Object.keys(valueTKESums[0]['values'] as any)) {
            this.valueTKEComparisonResults[key] =
                (valueTKESums[0]['values'] as any)[key] as any === (valueTKESums[1]['values'] as any)[key] as any;
            this.isComparisonMismatch = this.valueTKEComparisonResults[key] && this.isComparisonMismatch
        }

        this.includeWarningWhenComparisonMismatch()
    }

    calculateSums(array, marketCalc: boolean) {
        const sums = {};
        array.forEach(item => {
            item.KpiDetail.filter(x => (marketCalc &&
                ((item.COL3_NAME.toLowerCase() === 'competitor')
                    || (item.COL3_NAME.toLowerCase() === 'scope' && x.COL3.toLowerCase() === 'market'))) ||
                (!marketCalc && x.COL3.toLowerCase() === 'tke'))
                .forEach(detail => {
                    const key = detail.COL1 + "|" + detail.COL2;
                    if (typeof sums[item.AccountID] === 'undefined') {
                        sums[item.AccountID] = {
                            [key + '|PYBUD']: Number(detail.PYBUD) || 0,
                            [key + '|FY1BUD']: Number(detail.FY1BUD) || 0,
                            [key + '|FY2BUD']: Number(detail.FY2BUD) || 0,
                            [key + '|FY3BUD']: Number(detail.FY3BUD) || 0,
                        }
                    }
                    else if (typeof sums[item.AccountID][key + '|PYBUD'] === 'undefined') {
                        sums[item.AccountID][key + '|PYBUD'] = Number(detail.PYBUD) || 0;
                        sums[item.AccountID][key + '|FY1BUD'] = Number(detail.FY1BUD) || 0;
                        sums[item.AccountID][key + '|FY2BUD'] = Number(detail.FY2BUD) || 0;
                        sums[item.AccountID][key + '|FY3BUD'] = Number(detail.FY3BUD) || 0;
                    }
                    else {
                        sums[item.AccountID][key + '|PYBUD'] += Number(detail.PYBUD) || 0;
                        sums[item.AccountID][key + '|FY1BUD'] += Number(detail.FY1BUD) || 0;
                        sums[item.AccountID][key + '|FY2BUD'] += Number(detail.FY2BUD) || 0;
                        sums[item.AccountID][key + '|FY3BUD'] += Number(detail.FY3BUD) || 0;
                    }

                });
        });
        // Convert sums to an array
        const sumsArray = Object.entries(sums).map(([accountId, values]) => ({ accountId, values }));
        return sumsArray;
    }

    includeWarningWhenComparisonMismatch() {
        this.list.forEach((item: any) => {
            item.KpiDetail.forEach(detail => {
                this.yearlyKeys.forEach(bud => {
                    if (item.KpiUnit.toLowerCase() === 'values') {
                        if (item.COL3_NAME.toLowerCase() === 'competitor') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.valueMarketComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                        else if (item.COL3_NAME.toLowerCase() === 'scope' && detail.COL3.toLowerCase() === 'market') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.valueMarketComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                        else if (detail.COL3.toLowerCase() === 'tke') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.valueTKEComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                    }
                    else {
                        if (item.COL3_NAME.toLowerCase() === 'competitor') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.unitMarketComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                        else if (item.COL3_NAME.toLowerCase() === 'scope' && detail.COL3.toLowerCase() === 'market') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.unitMarketComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                        else if (detail.COL3.toLowerCase() === 'tke') {
                            detail[bud + 'Mismatch'] = detail[bud + 'Mismatch'] || !this.unitTKEComparisonResults[detail.COL1 + '|' + detail.COL2 + '|' + bud];
                        }
                    }
                });
                // Check if there is any mismatch at detail level
                detail['isComparisonMismatch'] = this.yearlyKeys.some(bud => detail[bud + 'Mismatch']);
            });

            // Check if there is any mismatch in the detail by checking if there is any true value in the Mismatch properties
            this.yearlyKeys.forEach(key => {
                item['isComparisonMismatch' + key] = item.KpiDetail.some(detail => detail[key + 'Mismatch']);
            })
        })
    }

    private getKeysWithZeroValue(type: string, obj): string[] {
        return Object.entries(obj)
            .filter(([key, value]) => value === false)
            .map(([key, value]) =>
                `${type} - ${key
                    .split('|')
                    .join(' ')
                    .replace('PYBUD', this.currentFY!)
                    .replace('FY1BUD', this.FY1!)
                    .replace('FY2BUD', this.FY2!)
                    .replace('FY3BUD', this.FY3!)
                }`
            );
    }

    private getFiscalYearsByKey() {
        this.FY3 = (this.defaults.fiscalYear + 2).toString() + '/' + (this.defaults.fiscalYear + 3).toString();
        this.FY2 = (this.defaults.fiscalYear + 1).toString() + '/' + (this.defaults.fiscalYear + 2).toString();
        this.FY1 = (this.defaults.fiscalYear).toString() + '/' + (this.defaults.fiscalYear + 1).toString();
        this.currentFY = (this.defaults.fiscalYear - 1).toString() + '/' + (this.defaults.fiscalYear).toString();
    }

    private initKpiHistoryLogDialogTags(kpiManagement: KpiManagement): void {       
        const prop2lookFor = ['COL1', 'COL2', 'COL3', 'COL4'];
        let tagsObject: kpiHistoryLogDialogTags = {
            BreakdownDescription1: { title: undefined, position: undefined },
            BreakdownDescription2: { title: undefined, position: undefined },
            MovementTypeDescription: { title: undefined, position: undefined },
            ProductHierarchyDescription: { title: undefined, position: undefined }
        };
        const tagsDic = Object.keys(tagsObject).map(x => ({ tag: x, auxTag: x.replace('Description', '') }));
        prop2lookFor.forEach((prop, index) => {
            if (kpiManagement.hasOwnProperty(prop)) {
                const propValue = kpiManagement[prop];
                let obj = tagsDic.find(x => x.auxTag === propValue);
                if (obj) {
                    tagsObject[obj.tag].title = kpiManagement[`${prop}_NAME`];
                    tagsObject[obj.tag].auxTitle = obj.auxTag;
                    tagsObject[obj.tag].position = index;
                }
            }
        });
        if (kpiManagement.KpiDetail)
            kpiManagement.KpiDetail.forEach(x => x.kpiHistoryLogDialogTags = tagsObject);
    }   
}
