import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent
} from '@azure/msal-angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { HomeComponent } from './components/home/home.component';
import { ChunkPipe } from './pipes/chunk.pipe';
import { MakeBold } from './pipes/makebold.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { DefaultValuesService } from './services/defaultvalues.service';
import { AlertConfirmComponent } from './components/dialogbox/alert-confirm.component';
import { WaitingService } from './services/waiting.service';
import { FilterService } from './services/filter.service';
import { DialogService } from './services/dialog.service';
import { MaterialModule } from './app.module.material';
import { NgSelectModule } from '@ng-select/ng-select';
import { TenantComponent } from './components/tenant/tenant.component';
import { NavMenuToolsComponent } from './components/navmenu/navmenu-tools.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ControllerTypeComponent } from './components/controllertype/controllertype.component';
import { StageComponent } from './components/stage/stage.component';
import { LanguageComponent } from './components/language/language.component';
import { SaveService } from './services/save.service';
import { FiscalYearComponent } from './components/fiscalyear/fiscalyear.component';
import { SpinnerOverlayService } from './services/spinner-overlay.service';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { PeriodComponent } from './components/period/period.component';
import { GroupFunctionComponent } from './components/groupfunction/groupfunction.component';
import { ManagementIdComponent } from './components/managementid/managementid.component';
import { OPLVersionComponent } from './components/oplversion/oplversion';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { OperationModeMenuComponent } from './components/operationmode/operationmode-menu.component';
import { OperationModeComponent } from './components/operationmode/operationmode.component';
import { OperationModeEditNewLanguageComponent } from './components/operationmode/operationmode-edit-newlanguage.component';
import { OperationModePropertiesListComponent } from './components/operationmode/operationmode-list-properties.component';
import { OperationModeDescriptionListComponent } from './components/operationmode/operationmode-list-description.component';
import { OperationModeOperationalCountersListComponent } from './components/operationmode/operationmode-list-opcounters.component';
import { OperationModeEditOpCountersComponent } from './components/operationmode/operationmode-edit-opcounters.component';
import { OperationModeNewComponent } from './components/operationmode/operationmode-new.component';
import { ErrorCodeMenuComponent } from './components/errorcode/errorcode-menu.component';
import { ErrorCodeComponent } from './components/errorcode/errorcode.component';
import { ErrorCodeDetailListDescriptionComponent } from './components/errorcode/errorcode-list-detail-description.component';
import { ErrorCodeDetailListPropertiesComponent } from './components/errorcode/errorcode-list-detail-properties.component';
import { ErrorCodeEditNewLanguageComponent } from './components/errorcode/errorcode-edit-newlanguage.component';
import { ErrorCodeListDescriptionComponent } from './components/errorcode/errorcode-list-description.component';
import { ErrorCodeListPropertiesComponent } from './components/errorcode/errorcode-list-properties.component';
import { ErrorCodeListNotMappedComponent } from './components/errorcode/errorcode-list-notmapped.component';
import { ErrorCodeNewComponent } from './components/errorcode/errorcode-new.component';
import { ErrorCodeNewDetailComponent } from './components/errorcode/errorcode-new-detail.component';
import { ErrorCodeEditDetailNewLanguageComponent } from './components/errorcode/errorcode-edit-detail-newlanguage.component';
import { ErrorCodeEditVirtualCoachComponent } from './components/errorcode/errorcode-edit-virtualcoach.component';
import { ErrorCodeListMLSuggestionsComponent } from './components/errorcode/errorcode-list-mlsuggestions.component';
import { ErrorCodeListMLSuggestionsUndoHistoryComponent } from './components/errorcode/errorcode-list-mlsuggestions-undo.component';
import { ConfirmationDialogComponent } from './components/dialogbox/dialog-confirmation.component';
import { AlertDialogComponent } from './components/dialogbox/dialog-alert.component';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory, getBaseUrl } from './app.module';
import { TableFixedHeaderSearchCollapseComponent } from './components/table/table-fixed-header-search-collapse.component';
import { TableFixedHeaderSearchComponent } from './components/table/table-fixed-header-search.component';
import { ControllerTypesDeploymentStatusPopup } from './components/field/controllertypes/controllertypes-deploymentstatus-popup.component';
import { SelectCheckBoxSearchComponent } from './components/table/select-checkbox-search.component';
import { AuthGuardService } from './services/authguard.service';
import { CustomDialogService } from './services/customdialog.service';
import { VirtualProvisioningComponent } from './components/virtualprovisioning/virtualprovisioning.component';
import { VirtualProvisioningListComponent } from './components/virtualprovisioning/virtualprovisioning-list.component';
import { PreregisterMacAddressComponent } from './components/virtualprovisioning/preregistermacaddress/preregistermacaddress.component';
import { CreateAssetComponent } from './components/virtualprovisioning/createasset/createasset.component';
import { NewLanguageComponent } from './components/new-language/new-language.component';
import { NewLanguageSelectorComponent } from './components/new-language/new-language-selector.component';
// Remote actions

import { RemoteActionsComponent } from './components/remoteactions/remoteactions.component';
import { AllowedActionControllerTypeComponent } from './components/remoteactions/allowedactionscontrollertype/allowedactionscontrollertype.component';
import { AllowedActionControllerTypeListComponent } from './components/remoteactions/allowedactionscontrollertype/allowedactionscontrollertype-list.component';
import { ProvisioningTemplateComponent } from './components/remoteactions/provisioningratemplate/provisioningratemplate.component';
import { ProvisioningTemplateListComponent } from './components/remoteactions/provisioningratemplate/provisioningratemplate-list.component';
import { ParametersConfigComponent } from './components/remoteactions/parametersconfig/parametersconfig.component';
import { ParametersConfigListComponent } from './components/remoteactions/parametersconfig/parametersconfig-list.component';
import { FunctionsConfigComponent } from './components/remoteactions/functionsconfig/functionsconfig.component';
import { FunctionsConfigListComponent } from './components/remoteactions/functionsconfig/functionsconfig-list.component';
import { FunctionsConfigPopupComponent } from './components/remoteactions/functionsconfig/functionsconfigpopup/functionsconfigpopup.component';
import { AllowedActionComponent } from './components/remoteactions/allowedactions/allowedactions.component';
import { AllowedActionListComponent } from './components/remoteactions/allowedactions/allowedactions-list.component';
import { RemoteResetCommandComponent } from './components/remoteactions/remoteresetcommand/remoteresetcommand.component';
import { RemoteResetCommandListComponent } from './components/remoteactions/remoteresetcommand/remoteresetcommand-list.component';
import { ParametersConfigPopupComponent } from './components/remoteactions/parametersconfig/parametersconfigpopup/parametersconfigpopup.component';
import { AddFunctionKeyPopupComponent } from './components/remoteactions/functionsconfig/add-functionkeypopup/add-functionkeypopup.component';
import { AddParameterKeyPopupComponent } from './components/remoteactions/parametersconfig/add-parameterkeypopup/add-parameterkeypopup.component';

//Test case section

import { TestCaseComponent } from './components/testcase/testcase.component';
import { TestCaseListComponent } from './components/testcase/testcase-list.component';
import { TestCaseMenuComponent } from './components/testcase/testcase-menu.component';
import { TestCaseFormComponent } from './components/testcase/testcase-form.component';
import { TestCaseFormCopyComponent } from './components/testcase/testcase-form-copy.component';
import { TestCaseFormDetailComponent } from './components/testcase/testcase-form-detail.component';
import { TestCaseFormJsonComponent } from './components/testcase/testcase-form-json.component';
import { TestCaseFormDatabricksTriggerComponent } from './components/testcase/testcase-form-databricks-trigger.component';
import { TestCaseListDatabricksTriggerComponent } from './components/testcase/testcase-list-databricks-trigger.component';
import { TestCaseExecuteComponent } from './components/testcase/testcase-execute.component';

// Maintenance warning
import { MaintenanceWarningComponent } from './components/maintenancewarning/maintenancewarning.component';
import { MaintenanceWarningListComponent } from './components/maintenancewarning/maintenancewarning-list.component';
import { MaintenanceWarningFormComponent } from './components/maintenancewarning/maintenancewarning-form.component';
import { MaintenanceWarningResolutionFormComponent } from './components/maintenancewarning/maintenancewarning-resolution-form.component';
import { MaintenanceWarningMenuComponent } from './components/maintenancewarning/maintenancewarning-menu.component';

// Maintenance control program
import { MaintenanceControlPlanComponent } from './components/maintenancecontrolplan/mcp.component';
import { MaintenanceControlPlanGroupActionVisualComponent } from './components/maintenancecontrolplan/mcp-group-action.component';
import { MaintenanceControlPlanGroupActionComponentListComponent } from './components/maintenancecontrolplan/mcp-list-group-action-component.component';
import { MaintenanceControlPlanGroupActionComponentNewComponent } from './components/maintenancecontrolplan/mcp-new-group-action-component.component';
import { MaintenanceControlPlanGroupActionComponentErrorNewComponent } from './components/maintenancecontrolplan/mcp-new-group-action-component-error.component';
import { MaintenanceControlPlanGroupActionComponentErrorListComponent } from './components/maintenancecontrolplan/mcp-list-group-action-component-error.component';
import { MaintenanceControlPlanGroupActionComponentProposedErrorsNewComponent } from './components/maintenancecontrolplan/mcp-new-group-action-component-proposed-errors.component';
import { MaintenanceControlPlanGroupActionComponentProposedErrorsListComponent } from './components/maintenancecontrolplan/mcp-list-group-action-component-proposed-errors.component';
import { MaintenanceControlPlanGroupActionListComponent } from './components/maintenancecontrolplan/mcp-list-group-action.component';
import { MaintenanceControlPlanGroupActionMenuComponent } from './components/maintenancecontrolplan/mcp-menu-group-action.component';
import { MaintenanceControlPlanGroupActionNewComponent } from './components/maintenancecontrolplan/mcp-new-group-action.component';
import { MaintenanceControlPlanGroupComponent } from './components/maintenancecontrolplan/mcp-group.component';
import { MaintenanceControlPlanGroupListComponent } from './components/maintenancecontrolplan/mcp-list-group.component';
import { MaintenanceControlPlanGroupMenuComponent } from './components/maintenancecontrolplan/mcp-menu-group.component';
import { MaintenanceControlPlanGroupNewComponent } from './components/maintenancecontrolplan/mcp-new-group.component';
import { MaintenanceControlPlanNewComponent } from './components/maintenancecontrolplan/mcp-new.component';
import { MaintenanceControlPlanListComponent } from './components/maintenancecontrolplan/mcp-list.component';
import { MaintenanceControlPlanMenuComponent } from './components/maintenancecontrolplan/mcp-menu.component';

// SEPE 08/11/2022
import { FingerprintRulesListComponent } from './components/fingerprintrules/legacy/fingerprintrules-list.component';
import { FingerprintRulesMenuComponent } from './components/fingerprintrules/legacy/fingerprintrules-menu.component';
import { FingerprintRulesComponent } from './components/fingerprintrules/fingerprintrules.component';
import { FingerprintRulesControllerConfigListComponent } from './components/fingerprintrules//fingerprintrules_controllerconfig-list.component';
import { FPErrorCodeListPropertiesComponent } from './components/fingerprintrules/fp_errorcode-list-properties.component';
import { FPErrorCodeMenuComponent } from './components/fingerprintrules/fp_errorcode-menu.component';
import { FPRuleDefinitionComponent } from './components/fingerprintrules/fp_rule_definition-list.component';
import { FPRuleDefinitionFormComponent } from './components/fingerprintrules/fp_rule_definition-form.component';

import { PagerService } from './services/pager.service';
import { ControllerTypeDeploymentStatusListComponent } from './components/field/controllertypes/controllertypedeploymentstatus-list.component';
import { ControllerTypesDeploymentStatus } from './components/field/controllertypes/controllertypes-deploymentstatus.component';
import { NonRecoverableErrorsComponent } from './components/erpintegration/nonrecoverableerrors/nonrecoverableerrors.component';
import { DigitalServicesComponent } from './components/digitalservices/digitalservices.component';

// Business Key
import { BusinessKeyComponent } from './components/businesskey/businesskey.component';
import { BusinessKeyFormComponent } from './components/businesskey/businesskey-form.component';
import { BusinessKeyListComponent } from './components/businesskey/businesskey-list.component';
import { BusinessKeyMenuComponent } from './components/businesskey/businesskey-menu.component';
import { TranslationAreaComponent } from './components/translationarea/translationarea.component';

// Harmonized OpMode
import { HarmonizedOperationModeComponent } from './components/field/specificequipmentstatus/harmonizedopmode.component';
import { HarmonizedOperationModeMenuComponent } from './components/field/specificequipmentstatus/harmonizedopmode-menu.component';
import { HarmonizedOperationModeListComponent } from './components/field/specificequipmentstatus/harmonizedopmode-list.component';

//AzDataFactory
import { AzDataFactoryFormComponent } from './components/erpintegration/azdatafactory/azdatafactoryform/azdatafactoryform.component';
import { AzDataFactoryListComponent } from './components/erpintegration/azdatafactory/azdatafactorylist/azdatafactorylist.component';
import { CustomerIdsListComponent } from './components/erpintegration/azdatafactory/customeridslist/customeridslist.component';

//Assets
import { AssetsComponent } from './components/erpintegration/assets/assets.component';
import { AssetsMenuComponent } from './components/erpintegration/assets/assets-menu.component';

import { ElevatorComponent } from './components/erpintegration/assets/elevator/elevator.component';
import { ElevatorListComponent } from './components/erpintegration/assets/elevator/elevator-list.component';
import { ElevatorFilterComponent } from './components/erpintegration/assets/elevator/elevator-filter.component';

import { EscalatorComponent } from './components/erpintegration/assets/escalator/escalator.component';
import { EscalatorListComponent } from './components/erpintegration/assets/escalator/escalator-list.component';
import { EscalatorFilterComponent } from './components/erpintegration/assets/escalator/escalator-filter.component';

import { OtherAssetComponent } from './components/erpintegration/assets/otherasset/otherasset.component';
import { OtherAssetFilterComponent } from './components/erpintegration/assets/otherasset/otherasset-filter.component';
import { OtherAssetListComponent } from './components/erpintegration/assets/otherasset/otherasset-list.component';
//import { CdpWikiListComponent } from './components/erpintegration/cdpwiki/cdpwiki-list.component';

import { ChairliftComponent } from './components/erpintegration/chairlift/chairlift.component';
import { ChairliftListComponent } from './components/erpintegration/chairlift/chairlift-list.component';
import { ChairliftFilterComponent } from './components/erpintegration/chairlift/chairlift-filter.component';
import { CdpWikiListComponent } from './components/erpintegration/cdpwiki/cdpwiki-list.component';
import { ChairliftMenuComponent } from './components/erpintegration/chairlift/chairlift-menu.component';


// Contracts
import { ContractComponent } from './components/erpintegration/contract/contract.component';
import { ContractMenuComponent } from './components/erpintegration/contract/contract-menu.component';

import { ContractItemFilterComponent } from './components/erpintegration/contract/contractitem/contractitem-filter.component';
import { ContractItemListComponent } from './components/erpintegration/contract/contractitem/contractitem-list.component';
import { ContractItemComponent } from './components/erpintegration/contract/contractitem/contractitem.component';

import { FacilityManagerComponent } from './components/erpintegration/contract/facilitymanager/facilitymanager.component';
import { FacilityManagerFilterComponent } from './components/erpintegration/contract/facilitymanager/facilitymanager-filter.component';
import { FacilityManagerListComponent } from './components/erpintegration/contract/facilitymanager/facilitymanager-list.component';

import { ContractBundleListComponent } from './components/erpintegration/contract/contractbundle/contractbundlelist.component';
import { ContractBundleComponent } from './components/erpintegration/contract/contractbundle/contractbundle.component';
import { ContractBundlePopupComponent } from './components/erpintegration/contract/contractbundle/contractbundlepopup.component';

import { CustomerComponent } from './components/erpintegration/customer/customer.component';
import { CustomerMenuComponent } from './components/erpintegration/customer/customer-menu.component';

import { CustomerItemListComponent } from './components/erpintegration/customer/customeritem/customeritem-list.component';
import { CustomerItemFilterComponent } from './components/erpintegration/customer/customeritem/customeritem-filter.component';
import { CustomerItemComponent } from './components/erpintegration/customer/customeritem/customeritem.component';

import { CustomerFacilityManagerListComponent } from './components/erpintegration/customer/facilitymanager/customerfacilitymanager-list.component';
import { CustomerFacilityManagerFilterComponent } from './components/erpintegration/customer/facilitymanager/customerfacilitymanager-filter.component';
import { CustomerFacilityManagerComponent } from './components/erpintegration/customer/facilitymanager/customerfacilitymanager.component';

import { KpiComponent } from './components/erpintegration/kpi/kpi.component';
import { KpiFilterComponent } from './components/erpintegration/kpi/kpi-filter.component';
import { KpiListComponent } from './components/erpintegration/kpi/kpi-list.component';
import { KpiMenuComponent } from './components/erpintegration/kpi/kpi-menu.component';

import { ServiceTicketComponent } from './components/erpintegration/service-ticket/service-ticket.component';
import { ServiceTicketFilterComponent } from './components/erpintegration/service-ticket/service-ticket-filter.component';
import { ServiceTicketListComponent } from './components/erpintegration/service-ticket/service-ticket-list.component';
import { ServiceTicketMenuComponent } from './components/erpintegration/service-ticket/service-ticket-menu.component';

import { ServicesDataCheckComponent } from './components/erpintegration/servicesdatacheck/servicesdatacheck.component'; //Sepe 06/2022

import { BranchHierarchyListComponent } from './components/erpintegration/branchhierarchy/branchhierarchy-list.component';
import { BranchHierarchyMenuComponent } from './components/erpintegration/branchhierarchy/branchhierarchy-menu.component';
import { BranchHierarchyComponent } from './components/erpintegration/branchhierarchy/branchhierarchy.component';

import { BackendSetupComponent } from './components/erpintegration/backendsetup/backendsetup.component';
import { BackendSetupMenuComponent } from './components/erpintegration/backendsetup/backendsetup-menu.component';
import { TechnicalUserSetupComponent } from './components/erpintegration/backendsetup/technicaluser/technicaluser.component';
import { CertificateComponent } from './components/erpintegration/backendsetup/certificate/certificate.component';
import { WsConfigurationComponent } from './components/erpintegration/backendsetup/wsconfiguration/wsconfiguration.component';
import { ControllerConfigFormComponent } from './components/devops/controllertypes/controllerconfig/controllerconfig-form.component';
import { ControllerConfigListComponent } from './components/devops/controllertypes/controllerconfig/controllerconfig-list.component';
import { ControllerConfigMenuComponent } from './components/devops/controllertypes/controllerconfig/controllerconfig-menu.component';
import { ControllerConfigComponent } from './components/devops/controllertypes/controllerconfig/controllerconfig.component';
import { ControllerConfigDescriptionListComponent } from './components/devops/controllertypes/controllerconfigdescription-list.component';
import { ControllerConfigDescriptionFormComponent } from './components/devops/controllertypes/controllerconfigdescription-form.component';
import { ControllerConfigPredefinedItemsDescriptionFormComponent } from './components/devops/controllertypes/controllerconfig/controllerconfigpredefineditemsdescription-form.component';
import { ControllerConfigPredefinedItemsDescriptionListComponent } from './components/devops/controllertypes/controllerconfigpredefineditemsdescription-list.component';
import { ControllerTypeFormComponent } from './components/devops/controllertypes/controllertype-form.component';
import { ControllerTypesComponent } from './components/devops/controllertypes/controllertypes.component';
import { ControllerTypesConfigurationsComponent } from './components/devops/controllertypes/controllertypes-configurations.component';
import { ControllerTypesDevOpsListComponent } from './components/devops/controllertypes/controllertypes-devops-list.component';
import { ControllerConfigControllerTypeToConnectionTypeComponent } from './components/devops/controllertypes/controllerconfig-controllertype2connectiontype.component';
import { ConnectionTypeListComponent } from './components/devops/controllertypes/connectiontype-list.component';
import { ConnectionTypeFormComponent } from './components/devops/controllertypes/connectiontype-form.component';
import { DefaultTransmissionModeListComponent } from './components/devops/controllertypes/transmissionmode/defaulttransmissionmode-list.component';
import { TransmissionModeFormComponent } from './components/devops/controllertypes/transmissionmode/transmissionmode-form.component';
import { AlertMergeConfigurationListComponent } from './components/devops/controllertypes/alertmergeconfiguration/alertmergeconfiguration-list.component';
import { AlertMergeConfigurationFormComponent } from './components/devops/controllertypes/alertmergeconfiguration/alertmergeconfiguration-form.component';
import { ControllerTypeToMasterdataRequiredListComponent } from './components/field/controllertypetomasterdatarequired/controllertypetomasterdatarequired-list.component';
import { ControllerTypeMasterDataRequiredComponent } from './components/field/controllertypetomasterdatarequired/controllertypetomasterdatarequired.component';
import { ControllerTypeToMasterDataRequiredMenuComponent } from './components/field/controllertypetomasterdatarequired/controllertypetomasterdatarequired-menu.component';
import { ControllerTypeByTenantListComponent } from './components/field/controllertypes/controllertypebytenant/controllertypebytenant-list.component';
import { ControllerTypeByTenantComponent } from './components/field/controllertypes/controllertypebytenant/controllertypebytenant.component';
import { ControllerTypeByTenantMenuComponent } from './components/field/controllertypes/controllertypebytenant/controllertypebytenant-menu.component';
import { ControllerTypeByTenantFormComponent } from './components/field/controllertypes/controllertypebytenant/controllertypebytenant-form.component';

import { DeploymentsDevOpsComponent } from './components/devops/deployment/deploymentsdevops.component';
import { DeployControllerTypesFormComponent } from './components/devops/deployment/deploycontrollertypes-form.component';
import { DeployErrorCodesOpModesFormComponent } from './components/devops/deployment/deployerrorcodesopmodes-form.component';
import { DeployBusinessKeysFormComponent } from './components/devops/deployment/deploybusinesskeys-form.component';
import { MonitoringDevOpsComponent } from './components/devops/monitoring/monitoringdevops.component';
import { ExeceptionLogComponent } from './components/devops/exceptionlog/exceptionlog.component';
import { ExceptionLogListComponent } from './components/devops/exceptionlog/exceptionlog-list.component';
import { TableMaterialComponent } from './components/table/table-material.component';

import { HierarchySetupComponent } from './components/hierarchysetup/hierarchysetup.component';
import { HierarchySetupMenuComponent } from './components/hierarchysetup/hierarchysetup-menu.component';
import { HierarchySetupListComponent } from './components/hierarchysetup/hierarchysetup-list.component';
import { HierarchySetupPopupComponent } from './components/hierarchysetup/hierarchysetuppopup/hierarchysetuppopup.component';
import { CountryComponent } from './components/country/country.component';

import { ChangeLogComponent } from './components/changelog/changelog.component';
import { ChangeLogListComponent } from './components/changelog/changelog-list.component';
import { ChangeLogMenuComponent } from './components/changelog/changelog-menu.component';
import { WikiNavComponent } from './components/wikinav/wikinav.component';
import { MarkdownModule, MarkdownService, SECURITY_CONTEXT } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';

//UMP
import { UmpHomeComponent } from './components/usermngportal/home/umphome.component';
import { UmpHomeListComponent } from './components/usermngportal/home/umphome-list.component';
import { GroupDAWorkspace } from './components/usermngportal/group/daworkspace/daworkspace.component';
import { CreateDaworkspaceComponent } from './components/usermngportal/group/daworkspace/createdaworkspace/createdaworkspace.component';
import { UMPAnalyticsComponent } from './components/usermngportal/umpanalytics/umpanalytics.component';
import { AdditionalUsersDialogue } from './components/usermngportal/group/additionalusersdialogue/additionalusersdialogue.component';
import { GroupMaxRoles } from './components/usermngportal/group/maxroles/maxroles.component';
import { AddGroupDialogue } from './components/usermngportal/home/edituser/addgroupdialogue/addgroupdialogue.component';
import { GroupMersyGroups } from './components/usermngportal/group/mersygroups/mersygroups.component';
import { GroupMersyRoles } from './components/usermngportal/group/mersyroles/mersyroles.component';
import { GroupUMPBranchHierarchy } from './components/usermngportal/group/umpbranchierarchy/umpbranchhierarchy.component';
import { GroupMgmtBranch } from './components/usermngportal/group/usermgmtbranch/usermgmtbranch.component';
import { GroupHome } from './components/usermngportal/group/grouphome.component';
import { AzDevProj } from './components/usermngportal/home/edituser/azdevproj/azdevproj.component';
import { MersyRoles } from './components/usermngportal/home/edituser/mersyroles/mersyroles.component';
import { MersyGroups } from './components/usermngportal/home/edituser/mersygroups/mersygroups.component';
import { DAWorkspace } from './components/usermngportal/home/edituser/daworkspace/daworkspace.component';
import { UMPBranchHierarchy } from './components/usermngportal/home/edituser/umpbranchhierarchy/umpbranchhierarchy.component';
import { UserMgmtBranch } from './components/usermngportal/home/edituser/usermgmtbranch/usermgmtbranch.component';
import { SpecialPermissions } from './components/usermngportal/home/edituser/specialpermissions/specialpermissions.component';
import { LicenseManagementComponent } from './components/usermngportal/home/edituser/licensemgmt/licensemgmt.component';
import { SearchAddon } from './components/usermngportal/home/edituser/resultsdialogue/searchaddon.component';
import { CacheInfoDialogue } from './components/usermngportal/home/edituser/cacheinfodialogue/cacheinfodialogue.component';
import { EditUserComponent } from './components/usermngportal/home/edituser/edituser.component';
import { DeleteUserDialogue } from './components/usermngportal/home/edituser/deleteuserdialogue/deleteuserdialogue.component';
import { ResultsDialogue } from './components/usermngportal/home/edituser/resultsdialogue/resultsdialogue.component';
import { ResetPwdDialogue } from './components/usermngportal/home/edituser/resetpwddialogue/resetpwddialogue.component';
import { AddUserTabComponent } from './components/usermngportal/home/adduser/adduser-tab.component';
import { InternalMaxUserComponent } from './components/usermngportal/home/adduser/internalmaxuser/internalmaxuser-form.component';
import { TKEUserComponent } from './components/usermngportal/home/adduser/tkeuser/tkeuser-form.component';
import { TKEBulkExtComponent } from './components/usermngportal/home/adduser/tkebulkextuser/tkebulkextuser.component';
import { CommonselectionListComponent } from './components/usermngportal/group/commonselectionlist/commonselection-list.component'

import { FileUploadModule } from 'ng2-file-upload';
import { PageNotFoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ImportFormComponent } from './components/fileupload/import-form.component';
import { ConfigurationComponent } from './components/devops/configuration/configuration.component';
import { ControllerTypesFieldComponent } from './components/field/controllertypes/controllertypes-field.component';
import { ControllerTypeHarmonizationComponent } from './components/commondataplatform/controllertypeharmonization/controllertypeharmonization.component';
import { ControllerTypeHarmonizationListComponent } from './components/commondataplatform/controllertypeharmonization/controllertypeharmonization-list.component';
import { ControllerTypeHarmonizationFormComponent } from './components/commondataplatform/controllertypeharmonization/controllertypeharmonization-form.component';
import { ControllerTypeHarmonizationMenuComponent } from './components/commondataplatform/controllertypeharmonization/controllertypeharmonization-menu.component';
import { BackendHarmonizationComponent } from './components/commondataplatform/backendharmonization/backendharmonization.component';
import { ServiceTicketCategoryListComponent } from './components/commondataplatform/backendharmonization/serviceticketcategory-list.component';
import { ServiceTicketCategoryFormComponent } from './components/commondataplatform/backendharmonization/serviceticketcategory-form.component';
import { ContractLineListComponent } from './components/commondataplatform/backendharmonization/contractline-list.component';
import { ContractLineFormComponent } from './components/commondataplatform/backendharmonization/contractline-form.component';
import { ContractTypeListComponent } from './components/commondataplatform/backendharmonization/contracttype-list.component';
import { ContractTypeFormComponent } from './components/commondataplatform/backendharmonization/contracttype-form.component';


import { MersySharepointComponent } from './components/commondataplatform/kpimanagement/mersy-sharepoint/mersy-sharepoint.component';
import { SectionSchedulerComponent } from './components/commondataplatform/kpimanagement/sectionscheduler/sectionscheduler.component';
import { SectionSchedulerListComponent } from './components/commondataplatform/kpimanagement/sectionscheduler/sectionscheduler-list.component';
import { AdminSectionComponent } from './components/commondataplatform/kpimanagement/kpiadmin/adminsection.component';
import { KpiAdminComponent } from './components/commondataplatform/kpimanagement/kpiadmin/kpiadmin.component';
import { KpiAdminGFFormComponent } from './components/commondataplatform/kpimanagement/kpiadmin/kpiadmingf-form.component';
import { KpiAdminNewKpiFormComponent } from './components/commondataplatform/kpimanagement/kpiadmin/kpiadminnewkpi-form.component';
import { KpiMersyReportComponent } from './components/commondataplatform/kpimanagement/mersyreport/kpimersyreport.component';

import { ActualReportingComponent } from './components/commondataplatform/kpimanagement/actualreporting/actualreporting.component';
import { ActualReportingListComponent } from './components/commondataplatform/kpimanagement/actualreporting/actualreporting-list.component';
import { KpiDetailFormComponent } from './components/commondataplatform/kpimanagement/kpidetail-form.component';
import { OPLKPIManagementComponent } from './components/commondataplatform/kpimanagement/opl/oplkpimanagement.component';
import { OPLKPIManagementListComponent } from './components/commondataplatform/kpimanagement/opl/oplkpimanagement-list.component';
import { OPLMonthlyKPIManagementListComponent } from './components/commondataplatform/kpimanagement/opl/oplmonthlykpimanagement-list.component';
import { DataTransferService } from './services/data-transfer.service';
import { DirectIndirectGeneric } from './components/usermngportal/home/edituser/directindirectgeneric/directindirectgeneric.component';
import { PopulationComponent } from './components/population/population.component';
import { HealthScoreComponent } from './components/healthscore/healthscore.component';
import { BranchExcellenceAdminComponent } from './components/commondataplatform/branchexcellence/admin/branchexcellenceadmin.component';
import { BranchConfigComponent } from './components/commondataplatform/branchexcellence/admin/branchconfig/branchconfig.component';
import { BranchContactComponent } from './components/commondataplatform/branchexcellence/admin/branchcontact/branchcontact.component';
import { CreateContactPopupComponent } from './components/commondataplatform/branchexcellence/admin/branchcontact/createcontact-popup/createcontact-popup.component';
import { AssignContactPopupComponent } from './components/commondataplatform/branchexcellence/admin/branchcontact/assigncontact-popup/assigncontact-popup.component';
import { RegulatoryAndDialerComponent } from './components/regulatoryanddialer/regulatoryanddialer.component';
import { DialerpropertiesListComponent } from './components/dialerproperties-list/dialerproperties-list.component';
import { UserHistoryDialogue } from './components/usermngportal/home/edituser/userhistorydialogue/userhistorydialogue.component';
import { HierarchysetuppopupListComponent } from './components/hierarchysetup/hierarchysetuppopup/hierarchysetuppopup-list/hierarchysetuppopup-list.component'
import { ExcellenceLeverComponent } from './components/commondataplatform/branchexcellence/admin/excellence-lever/excellence-lever.component';
import { ExcellenceLeverKpiComponent } from './components/commondataplatform/branchexcellence/admin/excellence-lever-kpi/excellence-lever-kpi.component';
import { ExcellenceFactorComponent } from './components/commondataplatform/branchexcellence/admin/excellence-factor/excellence-factor.component';
import { CreateExcellenceLeverPopupComponent } from './components/commondataplatform/branchexcellence/admin/excellence-lever/createexcellencelever-popup/createexcellencelever-popup.component';
import { CreateExcellenceLeverKpiPopupComponent } from './components/commondataplatform/branchexcellence/admin/excellence-lever-kpi/createexcellenceleverkpi-popup/createexcellenceleverkpi-popup.component';
import { CreateExcellenceFactorPopupComponent } from './components/commondataplatform/branchexcellence/admin/excellence-factor/createexcellencefactor-popup/createexcellencefactor-popup.component';
import { WaveComponent } from './components/commondataplatform/branchexcellence/admin/wave/wave.component';
import { CreateWavePopupComponent } from './components/commondataplatform/branchexcellence/admin/wave/createwavepopup/createwavepopup.component';
import { AssessmentAssignmentComponent } from './components/commondataplatform/branchexcellence/admin/assessment-assignment/assessment-assignment.component';
import { AssessmentAssignmentPopupComponent } from './components/commondataplatform/branchexcellence/admin/assessment-assignment/assessmentassignmentpopup/assessmentassignmentpopup.component';
import { AssessmentComponent } from './components/commondataplatform/branchexcellence/assessment/assessment.component';
import { AssessmentListComponent } from './components/commondataplatform/branchexcellence/assessment/assessment-list/assessment-list.component';
import { WaveSelectorComponent } from './components/wave/wave.component';
import { WaveExcellenceLeverConfigComponent } from './components/commondataplatform/branchexcellence/admin/waveexcellenceleverconfig/waveexcellenceleverconfig.component';
import { ImportExcellenceLeversPopup } from './components/commondataplatform/branchexcellence/admin/waveexcellenceleverconfig/importexcellencelevers-popup/importexcellencelevers-popup.component';
import { AssignWaveExcellenceLeverPopupComponent } from './components/commondataplatform/branchexcellence/admin/waveexcellenceleverconfig/assignwaveexcellencelever-popup/assignwaveexcellencelever-popup.component';
import { HistoryLeverDialog } from './components/commondataplatform/branchexcellence/assessment/history-lever-dialog/history-lever-dialog.component';
import { TDCComponents } from './components/tdccomponents/tdccomponents.component';
import { TDCFunctionType } from './components/tdccomponents/tdcfunctiontype/tdcfunctiontype.component';
import { TDCFamilyCode } from './components/tdccomponents/tdcfamilycode/tdcfamilycode.component';
import { TDCBOMCodeMapping } from './components/tdccomponents/tdcbomcodemapping/tdcbomcodemapping.component';
import { FocusPlanComponent } from './components/commondataplatform/branchexcellence/focusplan/focusplan.component';
import { FocusPlanListComponent } from './components/commondataplatform/branchexcellence/focusplan/focusplan-list/focusplan-list.component';
import { CreateMeasurePopupComponent } from './components/commondataplatform/branchexcellence/focusplan/create-measure/create-measure-popup.component';
import { CreateActionItemPopupComponent } from './components/commondataplatform/branchexcellence/focusplan/create-actionitem/create-action-item-popup.component';
import { EquipmentManagenementService } from './services/equipmentmanagement.service';
import { LevelHierarchyComponent } from './components/emtconfiguration/levelhierarchy/levelhierarchy.component';
import { EmtConfigurationComponent } from './components/emtconfiguration/emtconfiguration.component';
import { AddLevelComponent } from 'src/app/components/emtconfiguration/addlevel/addlevel.component';
import { EmtconfigurationListComponent } from 'src/app/components/emtconfiguration/emtconfiguration-list/emtconfiguration-list.component';
import { FocusPlanListImpactComponent } from './components/commondataplatform/branchexcellence/focusplan/focusplanimpact-list/focusplanimpact-list.component';
import { FocusPlanListStatusComponent } from './components/commondataplatform/branchexcellence/focusplan/focusplanstatus-list/focusplanstatus-list.component';
import { EMTControllerSoftwareRelease } from './components/emtcontrollersoftware/controllersoftwarerelease/controllersoftwarerelease.component';
import { EmtcontrollersoftwareComponent } from './components/emtcontrollersoftware/emtcontrollersoftware.component';
import { EmtProductusageComponent } from './components/emtcontrollersoftware/productusage/productusage.component';
import { EMTPeripheralSoftware } from './components/emtcontrollersoftware/peripheralsoftware/peripheralsoftware.component';
import { MarketSheetComponent } from './components/commondataplatform/market-query/market-sheet/market-sheet.component';
import { MarketSheetListComponent } from './components/commondataplatform/market-query/market-sheet/market-sheet-list.component';
import { FilterPopupComponent } from './components/commondataplatform/shared-components/filterpopup/filterpopup.component';
import { SegmentSheetComponent } from './components/commondataplatform/market-query/segment-sheet/segment-sheet.component';
import { SegmentSheetListComponent } from './components/commondataplatform/market-query/segment-sheet/segment-sheet-list.component';
import { ManufacturingCenterProductComponent } from './components/emtcontrollersoftware/manufacturingcenterproduct/manufacturingcenterproduct.component';
import { SfwVersionConfigGenerationComponent } from './components/emtcontrollersoftware/sfwversionconfiggeneration/sfwversionconfiggeneration.component'
import { JCOBRulesComponent } from './components/jcobrulescomponent/jcobrules.component';
import { FileMasterdataComponent } from './components/jcobrulescomponent/filemasterdata/filemasterdata.component';
import { NVStructMasterdata } from './components/jcobrulescomponent/nvstructmasterdata/nvstructmasterdata.component';
import { CommandsMasterdata } from './components/jcobrulescomponent/commandsmasterdata/commandsmasterdata.component';
import { TextAreaOverlayComponent } from './components/utils/textareaoverlay/textareaoverlay.component';
import { SearchBoldMismatch } from './pipes/search_bold_mismatch.pipe';
import { DestroyService } from './services/destroyservice';
import { ActionPriorizationListComponent } from './components/commondataplatform/branchexcellence/assessment/action-priorization-list/action-priorization-list.component';
import { GlobalTaskListComponent } from './components/global-task-list/global-task-list.component';
import { EmtTdcDeploymentStatusPopup } from './components/emttdcdeployment/emttdc-deploymentstatus-popup.component';
import { UrlSupportPopupComponent } from './components/global-task-list/urlsupport-popup/urlsupport-popup.component';
import { BranchconfigdatesPopup } from './components/commondataplatform/branchexcellence/admin/branchconfig/branchconfigdates-popup/branchconfigdates-popup.component';
import { MarketQueryPowerBiReportComponent } from './components/commondataplatform/market-query/powerbi-report/powerbi-report.component';
import { KpiAccountHistoryPopup } from './components/commondataplatform/kpimanagement/kpiadmin/kpiaccount-historydialog/kpiaccount-historydialog.component';
import { KpiAdminManagementService } from './services/kpiadminmanagement.service';
import { NiOpsComponent } from './components/commondataplatform/eoxniops/niopsmain/niops.component';
import { SoftwareReleaseToFieldComponent } from './components/emtcontrollersoftware/softwarereleasetofield/softwarereleasetofield.component';
import { SoftwareReleaseListComponent } from './components/emtcontrollersoftware/softwarereleasetofield/components/software-release-list/software-release-list.component';
import { AddSoftwareReleaseLevelComponent } from './components/emtcontrollersoftware/softwarereleasetofield/components/add-software-release-level/add-software-release-level.component';
import { SoftwareReleaseLevelsComponent } from './components/emtcontrollersoftware/softwarereleasetofield/components/software-release-levels/software-release-levels.component';
import { EoxNiOpsPowerBiReportComponent } from './components/commondataplatform/eoxniops/powerbi-report/eoxniops-powerbi-report.component';

@NgModule({
    declarations: [
        ChunkPipe,
        MakeBold,
        SearchBoldMismatch,
        AppComponent,
        HomeComponent,
        AlertConfirmComponent,

        TenantComponent,
        ControllerTypeComponent,
        StageComponent,
        LanguageComponent,
        FiscalYearComponent,
        OPLVersionComponent,
        PeriodComponent,
        PopulationComponent,
        GroupFunctionComponent,
        ManagementIdComponent,
        CountryComponent,
        WaveSelectorComponent,

        NavMenuToolsComponent,
        SidenavComponent,
        SpinnerOverlayComponent,
        OperationModeMenuComponent,
        OperationModeComponent,
        OperationModeEditNewLanguageComponent,
        OperationModePropertiesListComponent,
        OperationModeDescriptionListComponent,
        OperationModeOperationalCountersListComponent,
        OperationModeEditOpCountersComponent,
        OperationModeNewComponent,
        ErrorCodeComponent,
        ErrorCodeDetailListDescriptionComponent,
        ErrorCodeDetailListPropertiesComponent,
        ErrorCodeEditNewLanguageComponent,
        ErrorCodeListDescriptionComponent,
        ErrorCodeListPropertiesComponent,
        ErrorCodeListNotMappedComponent,
        ErrorCodeMenuComponent,
        ErrorCodeNewComponent,
        ErrorCodeNewDetailComponent,
        ErrorCodeEditDetailNewLanguageComponent,
        ErrorCodeEditVirtualCoachComponent,
        ErrorCodeListMLSuggestionsComponent,
        ErrorCodeListMLSuggestionsUndoHistoryComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        TableFixedHeaderSearchCollapseComponent,
        TableFixedHeaderSearchComponent,
        TableMaterialComponent,

        ControllerTypesDeploymentStatusPopup,
        SelectCheckBoxSearchComponent,
        VirtualProvisioningComponent,
        VirtualProvisioningListComponent,
        PreregisterMacAddressComponent,
        CreateAssetComponent,
        NewLanguageComponent,
        NewLanguageSelectorComponent,

        RemoteActionsComponent,
        AllowedActionComponent,
        AllowedActionListComponent,
        AllowedActionControllerTypeComponent,
        AllowedActionControllerTypeListComponent,
        ProvisioningTemplateComponent,
        ProvisioningTemplateListComponent,
        RemoteResetCommandComponent,
        RemoteResetCommandListComponent,

        ParametersConfigComponent,
        ParametersConfigListComponent,
        ParametersConfigPopupComponent,
        AddParameterKeyPopupComponent,

        FunctionsConfigComponent,
        FunctionsConfigListComponent,
        FunctionsConfigPopupComponent,
        AddFunctionKeyPopupComponent,

        TestCaseComponent,
        TestCaseListComponent,
        TestCaseMenuComponent,
        TestCaseFormComponent,
        TestCaseFormCopyComponent,
        TestCaseFormDetailComponent,
        TestCaseFormJsonComponent,
        TestCaseFormDatabricksTriggerComponent,
        TestCaseListDatabricksTriggerComponent,
        TestCaseExecuteComponent,

        MaintenanceWarningComponent,
        MaintenanceWarningListComponent,
        MaintenanceWarningFormComponent,
        MaintenanceWarningResolutionFormComponent,
        MaintenanceWarningMenuComponent,

        MaintenanceControlPlanComponent,
        MaintenanceControlPlanGroupActionComponentListComponent,
        MaintenanceControlPlanGroupActionComponentNewComponent,
        MaintenanceControlPlanGroupActionComponentErrorNewComponent,
        MaintenanceControlPlanGroupActionComponentErrorListComponent,
        MaintenanceControlPlanGroupActionComponentProposedErrorsNewComponent,
        MaintenanceControlPlanGroupActionComponentProposedErrorsListComponent,
        MaintenanceControlPlanGroupActionListComponent,
        MaintenanceControlPlanGroupActionMenuComponent,
        MaintenanceControlPlanGroupActionNewComponent,
        MaintenanceControlPlanGroupActionVisualComponent,
        MaintenanceControlPlanGroupComponent,
        MaintenanceControlPlanGroupListComponent,
        MaintenanceControlPlanGroupMenuComponent,
        MaintenanceControlPlanGroupNewComponent,
        MaintenanceControlPlanListComponent,
        MaintenanceControlPlanMenuComponent,
        MaintenanceControlPlanNewComponent,

        FingerprintRulesListComponent,
        FingerprintRulesMenuComponent,
        FingerprintRulesComponent,
        FingerprintRulesControllerConfigListComponent, //SEPE 10/22
        FPErrorCodeListPropertiesComponent,
        FPErrorCodeMenuComponent,
        FPRuleDefinitionComponent,
        FPRuleDefinitionFormComponent,

        ControllerTypeDeploymentStatusListComponent,
        ControllerTypesDeploymentStatus,

        NonRecoverableErrorsComponent, //082022 SEPE

        DigitalServicesComponent,

        BusinessKeyComponent,
        BusinessKeyFormComponent,
        BusinessKeyListComponent,
        BusinessKeyMenuComponent,
        TranslationAreaComponent,

        HarmonizedOperationModeComponent,
        HarmonizedOperationModeListComponent,
        HarmonizedOperationModeMenuComponent,

        AzDataFactoryFormComponent,
        AzDataFactoryListComponent,
        CustomerIdsListComponent,

        AssetsComponent,
        AssetsMenuComponent,

        ElevatorComponent,
        ElevatorListComponent,
        ElevatorFilterComponent,

        OtherAssetComponent,
        OtherAssetFilterComponent,
        OtherAssetListComponent,

        EscalatorComponent,
        EscalatorListComponent,
        EscalatorFilterComponent,

        CdpWikiListComponent,

        ChairliftComponent,
        ChairliftListComponent,
        ChairliftMenuComponent,
        ChairliftFilterComponent,

        ContractComponent,
        ContractMenuComponent,
        ContractItemComponent,
        ContractItemListComponent,
        ContractItemFilterComponent,
        ContractBundleListComponent,
        ContractBundleComponent,
        ContractBundlePopupComponent,

        FacilityManagerComponent,
        FacilityManagerFilterComponent,
        FacilityManagerListComponent,

        BranchHierarchyListComponent,
        BranchHierarchyMenuComponent,
        BranchHierarchyComponent,

        ServiceTicketComponent,
        ServiceTicketFilterComponent,
        ServiceTicketListComponent,
        ServiceTicketMenuComponent,
        ServicesDataCheckComponent, //062022 SEPE

        KpiComponent,
        KpiFilterComponent,
        KpiListComponent,
        KpiMenuComponent,

        CustomerComponent,
        CustomerMenuComponent,

        CustomerItemComponent,
        CustomerItemListComponent,
        CustomerItemFilterComponent,
        CustomerFacilityManagerComponent,
        CustomerFacilityManagerFilterComponent,
        CustomerFacilityManagerListComponent,

        BackendSetupComponent,
        BackendSetupMenuComponent,
        TechnicalUserSetupComponent,
        CertificateComponent,
        WsConfigurationComponent,

        ControllerConfigFormComponent,
        ControllerConfigListComponent,
        ControllerConfigMenuComponent,
        ControllerConfigComponent,
        ControllerConfigDescriptionListComponent,
        ControllerConfigDescriptionFormComponent,
        ControllerConfigPredefinedItemsDescriptionFormComponent,
        ControllerConfigPredefinedItemsDescriptionListComponent,
        ControllerTypeFormComponent,

        ControllerTypeByTenantListComponent,
        ControllerTypeByTenantComponent,
        ControllerTypeByTenantMenuComponent,
        ControllerTypeByTenantFormComponent,
        ControllerTypesComponent,
        ControllerTypesConfigurationsComponent,
        ControllerTypesDevOpsListComponent,
        ControllerConfigControllerTypeToConnectionTypeComponent,
        ConnectionTypeListComponent,
        ConnectionTypeFormComponent,

        DefaultTransmissionModeListComponent, //SEPE 062022
        TransmissionModeFormComponent, //SEPE 062022
        AlertMergeConfigurationListComponent,  //SEPE 072022
        AlertMergeConfigurationFormComponent, //SEPE 072022


        ControllerTypeToMasterdataRequiredListComponent,
        ControllerTypeMasterDataRequiredComponent,
        ControllerTypeToMasterDataRequiredMenuComponent,

        //Deployment devops
        DeploymentsDevOpsComponent,
        DeployControllerTypesFormComponent,
        DeployErrorCodesOpModesFormComponent,
        DeployBusinessKeysFormComponent,
        MonitoringDevOpsComponent, //Sepe 06/2022

        ExeceptionLogComponent,
        ExceptionLogListComponent,

        //HierarchySetup
        HierarchySetupComponent,
        HierarchySetupMenuComponent,
        HierarchySetupListComponent,
        HierarchySetupPopupComponent,
        HierarchysetuppopupListComponent,

        // HealthScore
        HealthScoreComponent,

        // ChangeLog

        ChangeLogComponent,
        ChangeLogListComponent,
        ChangeLogMenuComponent,

        WikiNavComponent,

        //UMP

        UmpHomeComponent,
        UmpHomeListComponent,
        GroupDAWorkspace,
        CreateDaworkspaceComponent,

        UMPAnalyticsComponent,
        AdditionalUsersDialogue,
        AddGroupDialogue,
        GroupMaxRoles,
        GroupMersyGroups,
        GroupMersyRoles,
        GroupUMPBranchHierarchy,
        GroupMgmtBranch,
        GroupHome,

        //UMP Home EditUser
        EditUserComponent,
        DAWorkspace,
        UMPBranchHierarchy,
        UserMgmtBranch,
        SpecialPermissions,
        LicenseManagementComponent,
        SearchAddon,
        MersyRoles,
        MersyGroups,
        AzDevProj,
        CacheInfoDialogue,
        DeleteUserDialogue,
        ResultsDialogue,
        ResetPwdDialogue,
        DirectIndirectGeneric,
        UserHistoryDialogue,

        //UMP Home AddUser
        AddUserTabComponent,
        InternalMaxUserComponent,
        TKEUserComponent,
        TKEBulkExtComponent,
        CommonselectionListComponent,

        PageNotFoundComponent,
        ImportFormComponent,
        ConfigurationComponent,
        ControllerTypesFieldComponent,

        // CDP
        ControllerTypeHarmonizationComponent,
        ControllerTypeHarmonizationListComponent,
        ControllerTypeHarmonizationFormComponent,
        ControllerTypeHarmonizationMenuComponent,

        BackendHarmonizationComponent,
        ServiceTicketCategoryListComponent,
        ServiceTicketCategoryFormComponent,
        ContractLineListComponent,
        ContractLineFormComponent,
        ContractTypeListComponent,
        ContractTypeFormComponent,

        MersySharepointComponent,
        KpiMersyReportComponent,
        AdminSectionComponent,

        KpiAdminComponent,
        KpiAdminGFFormComponent,
        KpiAdminNewKpiFormComponent,
        SectionSchedulerComponent,
        SectionSchedulerListComponent,
        FilterPopupComponent,

        ActualReportingComponent,
        ActualReportingListComponent,
        KpiDetailFormComponent,

        OPLKPIManagementComponent,
        OPLKPIManagementListComponent,
        OPLMonthlyKPIManagementListComponent,

        BranchExcellenceAdminComponent,
        BranchConfigComponent,
        BranchContactComponent,
        CreateContactPopupComponent,
        AssignContactPopupComponent,
        ExcellenceLeverComponent,
        ExcellenceLeverKpiComponent,
        ExcellenceFactorComponent,
        CreateExcellenceLeverPopupComponent,
        CreateExcellenceLeverKpiPopupComponent,
        CreateExcellenceFactorPopupComponent,
        WaveComponent,
        WaveExcellenceLeverConfigComponent,
        CreateWavePopupComponent,
        AssignWaveExcellenceLeverPopupComponent,
        AssessmentAssignmentComponent,
        AssessmentAssignmentPopupComponent,
        ImportExcellenceLeversPopup,
        AssessmentComponent,
        AssessmentListComponent,
        ActionPriorizationListComponent,
        HistoryLeverDialog,
        FocusPlanComponent,
        FocusPlanListComponent,
        CreateMeasurePopupComponent,
        CreateActionItemPopupComponent,
        FocusPlanListStatusComponent,
        FocusPlanListImpactComponent,

        RegulatoryAndDialerComponent,
        DialerpropertiesListComponent,
        TDCComponents,
        TDCFunctionType,
        TDCFamilyCode,
        TDCBOMCodeMapping,
        EmtConfigurationComponent,
        LevelHierarchyComponent,
        AddLevelComponent,
        EmtconfigurationListComponent,
        EmtcontrollersoftwareComponent,
        SoftwareReleaseToFieldComponent,
        AddSoftwareReleaseLevelComponent,
        SoftwareReleaseLevelsComponent,
        SoftwareReleaseListComponent,


        JCOBRulesComponent,
        FileMasterdataComponent,
        NVStructMasterdata,
        CommandsMasterdata,

        EMTControllerSoftwareRelease,
        EmtProductusageComponent,
        EMTPeripheralSoftware,
        ManufacturingCenterProductComponent,
        MarketSheetComponent,
        MarketSheetListComponent,
        SegmentSheetComponent,
        SegmentSheetListComponent,
        MarketQueryPowerBiReportComponent,
        SfwVersionConfigGenerationComponent,
        TextAreaOverlayComponent,
        GlobalTaskListComponent,
        UrlSupportPopupComponent,
        EmtTdcDeploymentStatusPopup,
        BranchconfigdatesPopup,
        KpiAccountHistoryPopup,

        //EOX NI Ops
        NiOpsComponent,
        EoxNiOpsPowerBiReportComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatButtonModule,
        MatToolbarModule,
        MatListModule,
        MatTableModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        HttpClientModule,
        FormsModule,
        MsalModule,
        MaterialModule,
        NgSelectModule,
        ReactiveFormsModule,
        MarkdownModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 5000, // 5 seconds
            progressBar: true,
        }),
        FileUploadModule,
        OverlayModule
    ],
    exports: [NgSelectModule,
        MatIconModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DefaultValuesService,
        WaitingService,
        FilterService,
        DialogService,
        SaveService,
        PagerService,
        DataTransferService,
        SpinnerOverlayService,
        MarkdownService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true
        },
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        AuthGuardService,
        CustomDialogService,
        DatePipe,
        EquipmentManagenementService,
        DestroyService,
        KpiAdminManagementService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
