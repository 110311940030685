import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { Utils } from '../../utils/utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'regulatoryanddialer',
    templateUrl: './regulatoryanddialer.component.html',
    styleUrls: ['../../style/mat-select.component.less', './regulatoryanddialer.component.less',],
})
export class RegulatoryAndDialerComponent {
    availableCodeBundleInfo: AvailableCodeBundle[] = [];
    allDialers: string[] = [];
    selectedRegCodeBundle: string | undefined;
    selectedDialer!: string;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private defaults: DefaultValuesService,
        private router: Router) { }

    ngOnInit() {
        this.selectedDialer = ''
        this.selectedRegCodeBundle = this.defaults.regulatoryCode;

        this.defaults.currentTenantObs$.subscribe((value) => value !== undefined ? (this.loadRegulatoryCodeBundles()) : null);
        this.defaults.stageMessageChanges$.subscribe((value) => value !== undefined ? (this.loadRegulatoryCodeBundles()) : null);

        this.loadRegulatoryCodeBundles();
    }

    loadRegulatoryCodeBundles() {
        this.availableCodeBundleInfo = [];
        this.allDialers = [];
        this.selectedDialer = ''
        this.selectedRegCodeBundle = '';
        this.defaults.updateRegCode('');
        Utils.httpGetRegulatoryCodeBundles(
            this.http
            , this.baseUrl
            , this.defaults.tenant
            , this
            , function (tthis: RegulatoryAndDialerComponent, response: RegulatoryCodeBundle) {
                tthis.availableCodeBundleInfo = response.availableCodeBundles;
                tthis.setDefaultRegulatoryCode();
            }
        )
    }

    setDefaultRegulatoryCode() {
        this.selectedRegCodeBundle =
            (this.availableCodeBundleInfo.find(bundle => bundle.default) || this.availableCodeBundleInfo[0]).regulatoryCodeBundleName;
        if (this.selectedRegCodeBundle) {
            this.updateDialers();
        }
    }

    onRegCodeChange() {
        this.updateDialers();
    }

    updateDialers() {
        var mappings = this.availableCodeBundleInfo
            .filter(x => x.regulatoryCodeBundleName === this.selectedRegCodeBundle)[0]
            .dialerTypeToCodeMappings;
        if (this.selectedRegCodeBundle && mappings) {
            this.allDialers = Object.keys(mappings);
            this.getRegCodeForDialer(true);
        }
    }

    getRegCodeForDialer(automaticCall: boolean) {
        if (automaticCall) {
            this.selectedDialer = this.defaults.dialerCode = this.allDialers[0];
        }
        else {
            this.defaults.dialerCode = this.selectedDialer
        }
        const selectedBundle = this.availableCodeBundleInfo
            .filter(x => x.regulatoryCodeBundleName === this.selectedRegCodeBundle)[0];

        this.defaults.updateRegCode(selectedBundle.dialerTypeToCodeMappings[this.selectedDialer]);
    }
}
