<div style="display: flex">

    <div class="defaultSelectProp">
        Regulatory Code Bundle:
        <br />
        <ng-select style="width: 230px; border: 1px white solid; margin: 0px;" class=" defaultSelectProp top-main-select"
                   [items]="availableCodeBundleInfo"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   bindLabel="regulatoryCodeBundleName"
                   bindValue="regulatoryCodeBundleName"
                   (change)="onRegCodeChange()"
                   [(ngModel)]="selectedRegCodeBundle">
        </ng-select>
    </div>

    <div class="defaultSelectProp">
        Device Type:
        <br />
        <ng-select style="width: 230px; border: 1px white solid; margin: 0px;" class=" defaultSelectProp top-main-select"
                   [items]="allDialers"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   (change)="getRegCodeForDialer(false)"
                   [(ngModel)]="selectedDialer">
        </ng-select>
    </div>

</div>
