import { Component, Output, Inject, EventEmitter, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, filter } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { NavigationEnd, Router } from '@angular/router';

//const API_URL = "api/language";

@Component({
    selector: 'language',
    template: `Language:<br />
                <ng-select (change)="onSelect($event)" style="width:230px; border: 1px white solid" class="top-main-select"
                    [items]="filteredLanguages"
                   bindLabel="Name"
                   bindValue="LanguageId"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.language.LanguageId">
                </ng-select>`
})

export class LanguageComponent implements OnInit {
    @Output() languageChanged = new EventEmitter<Language>();

    value: number = 0;

    public filteredLanguages: Language[] = [];

    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private router: Router
    ) {
    }

    private getUrl(): string { return this.baseUrl + Utils.getLanguageAPI(); }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(async (event: any) => {
                if (['/globaltasklist','/assessment'].includes(event.url)) {
                    Utils.httpGetAllLanguages(
                        this.http
                        , this.baseUrl
                        , this
                        , function (tthis: LanguageComponent, values: Language[]) {
                            tthis.filteredLanguages = values;
                            if (!tthis.filteredLanguages.find(x => x.LanguageId == tthis.defaults.language.LanguageId)) {
                                var eng = values.find(x => x.LanguageId === 50);
                                if (eng) {
                                    ['/assessment'].includes(event.url) ? tthis.onSelect(eng) : null;
                                }
                            }
                        }
                        , '?filter=' + event.url.substring(1)
                    );
                }
                else {
                    if (this.defaults.languages && this.defaults.languages.length > 0) {
                        this.filteredLanguages = this.defaults.languages;
                        if (!this.filteredLanguages.find(x => x.LanguageId == this.defaults.language.LanguageId))
                            this.defaults.language = { ...this.filteredLanguages[0] };
                        this.onSelect(this.defaults.language)
                    }
                }
            });

        this.dialog.tthis = this;
        this.defaults.languageMessageChanges$.subscribe(() => {
            if (!['/globaltasklist', '/assessment'].includes(this.router.url.toLocaleLowerCase()))
                this.filteredLanguages = this.defaults.languages
        }
        );
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    changeLang() {
        this.defaults.language = { ...this.filteredLanguages.filter(x => x.LanguageId == this.value)[0] };
        this.sendPost();
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeLang();
    }

    onSelect(l: Language) {
        if (!l)
            return;
        this.value = l.LanguageId;

        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();

    }

    onYes() {
        this.saveService.fireSave();
        this.changeLang();
    }

    sendPost() {
        var tthis = this;
        this.http
            .post<Language>(this.getUrl(), this.defaults.language)
            .subscribe(() => {
                if (this.languageChanged) {
                    this.languageChanged.emit(tthis.defaults.language);
                    this.defaults.onLanguageChanged.emit(tthis.defaults.language);
                    this.defaults.notifyLanguageChange(tthis.defaults.language);
                }
            }, error => Utils.writeLog(tthis.http, tthis.baseUrl, error)); //error => console.log(error));
    }
}