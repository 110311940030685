import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';





@Component({
    selector: "mersygroups",
    templateUrl: './mersygroups.component.html',
    styleUrls: ['./mersygroups.component.less', '../../../../../style/new-generic-styles.component.less'],

})

export class MersyGroups implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined; 
    @Input() public isOnlyNiOpsRequest: boolean | undefined;
    @Input() public isAdminRequest: boolean | undefined;
    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredMersyGroups: any[] = [];

    MersyGroups = [];
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;


    dialogRef: MatDialogRef<AddGroupDialogue, any> | undefined

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];



    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,

        private spinnerOverlayService: SpinnerOverlayService,

        @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });

       

    }


    ngOnInit() {

    }
    async updateRolesResults() {
        this.filteredMersyGroups = this.searchByValue(this.MersyGroups, "persona", this.searchString);
    }
    resetAll() {
        this.userName = '';
        this.userId = ''


        this.searchString = '';
        this.filteredMersyGroups = [];

        this.MersyGroups = [];
        this.Response_State = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
        this.isCallSucceed = 0;
        this.AllAzDevProj = [];
        this.UserAzDevProj = [];
        this.RemainAzDevProj = [];

        this.updateRolesResults();

        this.userId = this.route.snapshot.paramMap.get('userId') || '';

        this.funcGetUserMersyGroups();
    }

    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }


    handleDeleteButton(selected: any) {
        

        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [selected.id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": UMPCategory.MersyGroupFunctions,
                "Type": 2
            }
            , this.userId           
            , this
            , function (tthis: MersyGroups) {
                tthis.funcGetUserMersyGroups();
                tthis.funcNotify(selected.persona);
            }
        )
        

    }
    funcGetUserMersyGroups() {
        this.Response_State = -2;
        Utils.httpGetUserMersyGroups(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: MersyGroups, data: any) {
                if (tthis.isAdminRequest) {
                    tthis.MersyGroups = data.personae;
                } else {
                    tthis.MersyGroups = data.personae.filter(obj =>
                        tthis.isOnlyNiOpsRequest ? obj.persona.toLowerCase().includes("niops")
                            : !obj.persona.toLowerCase().includes("niops"))
                }
                tthis.UserAzDevProj = data.personae;
                tthis.filteredMersyGroups = tthis.MersyGroups

                if (tthis.MersyGroups?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;

                }
            }
        )
    }
    funcNotify(persona) {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Delete "' + persona + '"  request sent successfully !', 'Mersy Group Role Delete Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Delete "' + persona + '"  request not sent successfully !', 'Mersy Group Role Delete Request!');
        }
    }

    //DialogueForm generalised  
    funcGetMersyGroups() {
        Utils.httpGetMersyGroups(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: MersyGroups, data: any) {
                if (tthis.isAdminRequest) {
                    tthis.AllAzDevProj = data.personae;
                } else {
                    tthis.AllAzDevProj = data.personae.filter(obj =>
                        tthis.isOnlyNiOpsRequest ? obj.persona.toLowerCase().includes("niops")
                            : !obj.persona.toLowerCase().includes("niops"))
                }
                //tthis.funcGetUserMersyGroups_seq();
                tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);

            }
        )
    }



    //funcGetUserMersyGroups_seq() {
    //    Utils.httpGetUserMersyGroups(
    //        this.http
    //        , this.baseUrl
    //        , this.userId
    //        , this
    //        , function (tthis: MersyGroups, data: any) {
    //            tthis.UserAzDevProj = data.personae;
    //            tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);
    //        }
    //    )
    //}



    getUnselectedItems(allList: any, selectedList: any) {

        this.RemainAzDevProj = allList.filter(item => !selectedList.some(selectedItem => selectedItem.id === item.id));
        this.opnedialoguebox()
    }

    addUserTrigger() {
        
        this.funcGetMersyGroups();
    };

    opnedialoguebox() {
        this.dialogRef = this.dialogForm.open(AddGroupDialogue, {
           height: '540px',
            width: '600px',
            data: {
                title: "Mersy Group Functions", umpCategory: UMPCategory.MersyGroupFunctions, userId: this.userId, AllGroups: this.AllAzDevProj, UserAssignedGroups: this.UserAzDevProj, UserUnAssignedGroups: this.RemainAzDevProj, type: 2, callback: this.groupmgmtcallback
            }
        });
        this.dialogRef.afterClosed().subscribe(() => {
            
            this.funcGetUserMersyGroups();
        });
    }
}