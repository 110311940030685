import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './components/home/home.component';
import { AppComponent } from './app.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { ErrorCodeComponent } from './components/errorcode/errorcode.component';
import { AuthGuardService } from './services/authguard.service';
import { OperationModeComponent } from './components/operationmode/operationmode.component';
import { VirtualProvisioningComponent } from './components/virtualprovisioning/virtualprovisioning.component';
import { NewLanguageComponent } from './components/new-language/new-language.component';
import { RemoteActionsComponent } from './components/remoteactions/remoteactions.component';
import { TestCaseComponent } from './components/testcase/testcase.component';
import { MaintenanceWarningComponent } from './components/maintenancewarning/maintenancewarning.component';
import { MaintenanceWarningFormComponent } from './components/maintenancewarning/maintenancewarning-form.component';
import { MaintenanceWarningResolutionFormComponent } from './components/maintenancewarning/maintenancewarning-resolution-form.component';
import { MaintenanceControlPlanComponent } from './components/maintenancecontrolplan/mcp.component';
import { MaintenanceControlPlanGroupComponent } from './components/maintenancecontrolplan/mcp-group.component';
import { MaintenanceControlPlanGroupActionVisualComponent } from './components/maintenancecontrolplan/mcp-group-action.component';
import { MaintenanceControlPlanGroupActionComponentProposedErrorsNewComponent } from './components/maintenancecontrolplan/mcp-new-group-action-component-proposed-errors.component';
import { FingerprintRulesComponent } from './components/fingerprintrules/fingerprintrules.component';
import { ControllerTypesDeploymentStatus } from './components/field/controllertypes/controllertypes-deploymentstatus.component';
import { NonRecoverableErrorsComponent } from './components/erpintegration/nonrecoverableerrors/nonrecoverableerrors.component';
import { DigitalServicesComponent } from './components/digitalservices/digitalservices.component';
import { BusinessKeyComponent } from './components/businesskey/businesskey.component';
import { HarmonizedOperationModeComponent } from './components/field/specificequipmentstatus/harmonizedopmode.component';
import { AssetsComponent } from './components/erpintegration/assets/assets.component';
import { ChairliftComponent } from './components/erpintegration/chairlift/chairlift.component';
import { ContractComponent } from './components/erpintegration/contract/contract.component';
import { CustomerComponent } from './components/erpintegration/customer/customer.component';
import { BackendSetupComponent } from './components/erpintegration/backendsetup/backendsetup.component';
import { ServiceTicketComponent } from './components/erpintegration/service-ticket/service-ticket.component';
import { KpiComponent } from './components/erpintegration/kpi/kpi.component';
import { ServicesDataCheckComponent } from './components/erpintegration/servicesdatacheck/servicesdatacheck.component';
import { BranchHierarchyComponent } from './components/erpintegration/branchhierarchy/branchhierarchy.component';

//DevOpsMenu ControllerTypes

import { ControllerTypesConfigurationsComponent } from './components/devops/controllertypes/controllertypes-configurations.component';
import { ControllerTypesComponent } from './components/devops/controllertypes/controllertypes.component';
import { ControllerTypeFormComponent } from './components/devops/controllertypes/controllertype-form.component';
import { ControllerConfigFormComponent } from './components/devops/controllertypes/controllerconfig/controllerconfig-form.component';
import { ControllerConfigPredefinedItemsDescriptionFormComponent } from './components/devops/controllertypes/controllerconfig/controllerconfigpredefineditemsdescription-form.component';
import { ConnectionTypeFormComponent } from './components/devops/controllertypes/connectiontype-form.component';

import { TransmissionModeFormComponent } from './components/devops/controllertypes/transmissionmode/transmissionmode-form.component'; // SEPE 06/2022
import { AlertMergeConfigurationFormComponent } from './components/devops/controllertypes/alertmergeconfiguration/alertmergeconfiguration-form.component'; // SEPE 07/2022
import { DeploymentsDevOpsComponent } from './components/devops/deployment/deploymentsdevops.component';
import { MonitoringDevOpsComponent } from './components/devops/monitoring/monitoringdevops.component';
import { ExeceptionLogComponent } from './components/devops/exceptionlog/exceptionlog.component';
import { HierarchySetupComponent } from './components/hierarchysetup/hierarchysetup.component';
import { ChangeLogComponent } from './components/changelog/changelog.component';
import { UmpHomeComponent } from './components/usermngportal/home/umphome.component';
import { UMPAnalyticsComponent } from './components/usermngportal/umpanalytics/umpanalytics.component';
import { EditUserComponent } from './components/usermngportal/home/edituser/edituser.component';
import { GroupHome } from './components/usermngportal/group/grouphome.component';
import { ImportFormComponent } from './components/fileupload/import-form.component';
import { ConfigurationComponent } from './components/devops/configuration/configuration.component';
import { PageNotFoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ControllerTypeHarmonizationComponent } from './components/commondataplatform/controllertypeharmonization/controllertypeharmonization.component';
import { MersySharepointComponent } from './components/commondataplatform/kpimanagement/mersy-sharepoint/mersy-sharepoint.component';
import { AdminSectionComponent } from './components/commondataplatform/kpimanagement/kpiadmin/adminsection.component';
import { KpiMersyReportComponent } from './components/commondataplatform/kpimanagement/mersyreport/kpimersyreport.component';
import { ActualReportingComponent } from './components/commondataplatform/kpimanagement/actualreporting/actualreporting.component';
import { OPLKPIManagementComponent } from './components/commondataplatform/kpimanagement/opl/oplkpimanagement.component';
import { HealthScoreComponent } from './components/healthscore/healthscore.component';
import { BranchExcellenceAdminComponent } from './components/commondataplatform/branchexcellence/admin/branchexcellenceadmin.component';
import { DialerpropertiesListComponent } from './components/dialerproperties-list/dialerproperties-list.component';
import { AssessmentComponent } from './components/commondataplatform/branchexcellence/assessment/assessment.component';
import { TDCComponents } from './components/tdccomponents/tdccomponents.component';
import { FocusPlanComponent } from './components/commondataplatform/branchexcellence/focusplan/focusplan.component';
import { EmtConfigurationComponent } from './components/emtconfiguration/emtconfiguration.component';
import { EmtcontrollersoftwareComponent } from './components/emtcontrollersoftware/emtcontrollersoftware.component';
import { MarketSheetComponent } from './components/commondataplatform/market-query/market-sheet/market-sheet.component';
import { SegmentSheetComponent } from './components/commondataplatform/market-query/segment-sheet/segment-sheet.component';
import { JCOBRulesComponent } from './components/jcobrulescomponent/jcobrules.component';
import { GlobalTaskListComponent } from './components/global-task-list/global-task-list.component';
import { MarketQueryPowerBiReportComponent } from './components/commondataplatform/market-query/powerbi-report/powerbi-report.component';
import { NiOpsComponent } from './components/commondataplatform/eoxniops/niopsmain/niops.component';
import { EoxNiOpsPowerBiReportComponent } from './components/commondataplatform/eoxniops/powerbi-report/eoxniops-powerbi-report.component';

/**
 * MSAL Angular can protect routes in your application using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'errorcode', component: ErrorCodeComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'opmodes', component: OperationModeComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'virtualprovisioning', component: VirtualProvisioningComponent
    },
    { path: 'language/new', component: NewLanguageComponent },
    {
        path: 'remoteactions', component: RemoteActionsComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'testcases', component: TestCaseComponent, canActivate: [AuthGuardService], data: {
            role: 'it_domainexpert_user'
        }
    },
    {
        path: 'maintenancewarning', component: MaintenanceWarningComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },

    {
        path: 'maintenancewarning', component: MaintenanceWarningComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'maintenancewarning-form', component: MaintenanceWarningFormComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'globaltasklist', component: GlobalTaskListComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    { path: 'maintenancewarning-resolution-form', component: MaintenanceWarningResolutionFormComponent },
    {
        path: 'maintenancecontrolplan', component: MaintenanceControlPlanComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'maintenancecontrolplangroup/:id', component: MaintenanceControlPlanGroupComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'maintenancecontrolplangroupaction/:id', component: MaintenanceControlPlanGroupActionVisualComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'maintenancecontrolplangroupactioncomponentsproposederrors/:id', component: MaintenanceControlPlanGroupActionComponentProposedErrorsNewComponent
        , canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'fingerprint-rules', component: FingerprintRulesComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'controllertypes-deploymentstatus', component: ControllerTypesDeploymentStatus, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    { path: 'nonrecoverableerrors', component: NonRecoverableErrorsComponent }, //SEPE 08/22 Non Recoverable Erros
    {
        path: 'digitalservices', component: DigitalServicesComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'businesskey', component: BusinessKeyComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'harmonizedopmodes', component: HarmonizedOperationModeComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },

    {
        path: 'assets', component: AssetsComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'chairlift', component: ChairliftComponent, canActivate: [AuthGuardService], data: {
            role: 'homeuser'
        }
    },
    {
        path: 'contract', component: ContractComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },

    {
        path: 'customer', component: CustomerComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'backend-setup', component: BackendSetupComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'service-ticket', component: ServiceTicketComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'kpi', component: KpiComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'branch-hierarchy', component: BranchHierarchyComponent, canActivate: [AuthGuardService], data: {
            role: 'countrybackenduser'
        }
    },
    {
        path: 'tenantdialerconfig', component: DialerpropertiesListComponent, canActivate: [AuthGuardService], data: {
            role: 'globalsystemadmin'
        }
    },
    { path: 'servicesdatacheck', component: ServicesDataCheckComponent }, //SEPE 06/22 Services Data Check

    { path: 'controllerconfig-form', component: ControllerConfigFormComponent },
    { path: 'controllerconfig-predefined-items-description-form', component: ControllerConfigPredefinedItemsDescriptionFormComponent },
    { path: 'controllertype-form', component: ControllerTypeFormComponent },
    { path: 'connectiontype-form', component: ConnectionTypeFormComponent },
    { path: 'controllertypes', component: ControllerTypesComponent },
    {
        path: 'controllertypes-configurations', component: ControllerTypesConfigurationsComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    { path: 'transmissionmode-form', component: TransmissionModeFormComponent }, // SEPE 06/2022
    { path: 'alertmergeconfiguration-form', component: AlertMergeConfigurationFormComponent }, // SEPE 06/2022
    {
        path: 'deploymentsdevops', component: DeploymentsDevOpsComponent, canActivate: [AuthGuardService], data: {
            role: 'admin'
        }
    },
    { path: 'monitoringdevops', component: MonitoringDevOpsComponent }, //SEPE 06/22 DevOps Monitoring

    {
        path: 'exceptions', component: ExeceptionLogComponent, canActivate: [AuthGuardService], data: {
            role: 'admin'
        }
    },
    { path: 'changelog', component: ChangeLogComponent },

    {
        path: 'UmpHome', component: UmpHomeComponent, canActivate: [AuthGuardService], data: {
            role: 'umpuser'
        }
    },
    {
        path: 'EditUser/:userId', component: EditUserComponent, canActivate: [AuthGuardService], data: {
            role: 'umpuser'
        }
    },

    {
        path: 'Group', component: GroupHome, canActivate: [AuthGuardService], data: {
            role: 'umpuser'
        }
    },
    {
        path: 'UMPAnalytics', component: UMPAnalyticsComponent, canActivate: [AuthGuardService], data: {
            role: 'umpuser'
        }
    },
    { path: 'file/:resource', component: ImportFormComponent },
    { path: 'configurationsettings', component: ConfigurationComponent },
    { path: 'ControllerTypeHarmonization', component: ControllerTypeHarmonizationComponent }, //SEPE 06/22 CDP->ControllerTypeHarmonization

    {
        path: 'mersy-sharepoint', component: MersySharepointComponent, canActivate: [AuthGuardService], data: {
            role: 'umpuser'
        }
    },

    {
        path: 'adminsection', component: AdminSectionComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    },

    {
        path: 'kpimersyreport', component: KpiMersyReportComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    },

    {
        path: 'actualreporting', component: ActualReportingComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    },

    {
        path: 'oplkpimanagement', component: OPLKPIManagementComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    },
    {
        path: 'actualbranchreporting', component: ActualReportingComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    }, //SEPE 03/2023
    {
        path: 'oplkpibranchreporting', component: OPLKPIManagementComponent, canActivate: [AuthGuardService], data: {
            role: 'mersyuser'
        }
    },
    {
        path: 'healthscore', component: HealthScoreComponent, canActivate: [AuthGuardService], data: {
            role: 'domainexpertuser'
        }
    },
    {
        path: 'branch-excellence-admin', component: BranchExcellenceAdminComponent, canActivate: [AuthGuardService], data: {
            role: 'branchexcellence'
        }
    },
    {
        path: 'assessment', component: AssessmentComponent, canActivate: [AuthGuardService], data: {
            role: 'branchexcellence'
        }
    },
    {
        path: 'focus-plan', component: FocusPlanComponent, canActivate: [AuthGuardService], data: {
            role: 'branchexcellence'
        }
    },
    {
        path: 'hierarchysetup', component: HierarchySetupComponent, canActivate: [AuthGuardService], data: {
            role: 'usermgmtfldbranchesuser'
        }
    },
    {
        path: 'tdccomponents', component: TDCComponents, canActivate: [AuthGuardService], data: {
            role: 'mfgadmin'
        }
    },
    {
        path: 'equipment-activation',
        component: EmtConfigurationComponent,
        canActivate: [AuthGuardService],
        data: { role: 'equipmentmfg' }
    },
    {
        path: 'emtcontrollersoftware', component: EmtcontrollersoftwareComponent, canActivate: [AuthGuardService], data: {
            role: 'controllersoftwareuser'
        }
    },
    {
        path: 'jcobrules', component: JCOBRulesComponent, canActivate: [AuthGuardService], data: {
            role: 'mfgadmin'
        }
    },
    {
        path: 'market-sheet', component: MarketSheetComponent, canActivate: [AuthGuardService], data: {
            role: 'marketquery'
        }
    },
    {
        path: 'segment-sheet', component: SegmentSheetComponent, canActivate: [AuthGuardService], data: {
            role: 'marketquery'
        }
    },
    {
        path: 'marketquery-report', component: MarketQueryPowerBiReportComponent, canActivate: [AuthGuardService], data: {
            role: 'marketquery'
        }
    },
    {
        path: 'niops', component: NiOpsComponent, canActivate: [AuthGuardService], data: {
            role: 'niopsuser'
        }
    },
    {
        path: 'eoxniops-report', component: EoxNiOpsPowerBiReportComponent, canActivate: [AuthGuardService], data: {
            role: 'niopsuser'
        }
    },
    { path: '**', component: PageNotFoundComponent }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
