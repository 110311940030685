import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { SaveService } from '../../../../../services/save.service';
import { ComponentTool } from '../../../../../interfaces/componenttool';





@Component({
    selector: "directindirectgeneric",
    templateUrl: './directindirectgeneric.component.html',
    styleUrls: ['./directindirectgeneric.component.less', '../../../../../style/new-generic-styles.component.less'],

})

export class DirectIndirectGeneric implements OnInit {
    @Input() componentName: string = '';
    @Input() umpCategoryName: string = 'None';

    componentAPIName = '';

    roleSearchValue: string = '';
    userId = '';

    filteredDirectRoleItems: any[] = [];
    filteredInDirRoleItems = [];
    fullDirectRoleItems = [];
    fullInDirRoleItems = [];

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];

    addgrpdialogRef: MatDialogRef<AddGroupDialogue, any> | undefined


    componentToolEnum: typeof ComponentTool = ComponentTool;

    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    isCallSucceed: number = 0;
    errorResponse: string = '';

    umpCategory: UMPCategory = UMPCategory.None;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        private defaults: DefaultValuesService,
        private customDialog: CustomDialogService,
        private saveService: SaveService,
        public topDetlRef: ElementRef

    ) {



    }

    ngOnInit() {
        this.componentAPIName = this.componentName.replace(/\s/g, "").toLowerCase()
        this.route.params.subscribe(
            (params: Params) => {
                this.constructorfunc();
            });
        this.umpCategory = this.getUmpCategory(this.umpCategoryName);
    }
    constructorfunc() {
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetDirIndirUserRoles();
    }


    async updateRolesResults() {
        this.filteredDirectRoleItems = this.searchByValue(this.fullDirectRoleItems, "persona", this.roleSearchValue);
        this.filteredInDirRoleItems = this.searchByValue(this.fullInDirRoleItems, "persona", this.roleSearchValue);
    }

    searchByValue(items: any, filterProperty: string, query: string) {
        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }

    funcGetDirIndirUserRoles() {
        this.Response_State = -2;
        Utils.httpGetDirIndirUserRoles(
            this.http
            , this.baseUrl
            , this.componentAPIName
            , this.userId
            , this
            , function (tthis: DirectIndirectGeneric, data: any) {
                tthis.fullDirectRoleItems = data.directPersonae;
                tthis.fullInDirRoleItems = data.inDirectPersonae;

                tthis.filteredInDirRoleItems = data.inDirectPersonae;
                tthis.filteredDirectRoleItems = data.directPersonae;

                if (tthis.filteredDirectRoleItems?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;
                }
            }
        )
    }
    funcNotifyRoles(persona) {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Delete "' + persona + '"  request sent successfully !', this.componentName + ' Delete Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Delete "' + persona + '"  request not sent successfully !', this.componentName + ' Delete Request!');
        }
    }

    //DialogueForm generalised  
    funcGetDirIndirRemainingRoles() {
        Utils.httpGetDirIndirRemainingRoles(
            this.http
            , this.baseUrl
            , this.componentAPIName
            , this.userId
            , this
            , function (tthis: DirectIndirectGeneric, data: any) {
                tthis.RemainAzDevProj = data.personae;
                tthis.opnedialoguebox()
            }
        )

    }

    addUserTrigger() {


        this.funcGetDirIndirRemainingRoles();
    };

    opnedialoguebox() {
        this.addgrpdialogRef = this.dialogForm.open(AddGroupDialogue, {
            height: '540px',
            width: '600px',
            data: { title: this.componentName, umpCategory: this.umpCategory, userId: this.userId, AllGroups: this.AllAzDevProj, UserAssignedGroups: this.UserAzDevProj, UserUnAssignedGroups: this.RemainAzDevProj, IsDirIndirComponent: true }
        });
        this.addgrpdialogRef.afterClosed().subscribe(() => {

            this.funcGetDirIndirUserRoles();
        });
    }

    handleDeleteButton(selected: any) {


        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [selected.id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": this.umpCategory,
                "Type": 1
            }
            , this.userId
            , this
            , function (tthis: DirectIndirectGeneric) {
                tthis.funcGetDirIndirUserRoles();
                tthis.funcNotifyRoles(selected.persona);
            }
        )




    }
    showIndirectRole() {
        return this.componentName.toLowerCase().includes('max') || this.filteredInDirRoleItems.length > 0
    }

    getUmpCategory(umpCategoryName) {

        let category = UMPCategory[umpCategoryName as keyof typeof UMPCategory];
        if (category !== undefined)
            return category;
        else
            return UMPCategory.None;
    }
}
