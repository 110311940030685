import { Component, Inject, OnInit } from '@angular/core';
import { Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { CreateExcellenceFactorPopupComponent } from './createexcellencefactor-popup/createexcellencefactor-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { Router } from '@angular/router';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';

@Component({
    selector: 'excellence-factor',
    templateUrl: './excellence-factor.component.html',
    styleUrls: ['./excellence-factor.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class ExcellenceFactorComponent implements OnInit {

    subscriptions: any[] = [];
    public dataSource = new MatTableDataSource<ExcellenceFactor>();
    columnsToDisplay = ['Name', 'Description', 'ExcellenceLever', 'ChangedAtUtc', 'ChangedBy', 'actions'];

    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
        , private router: Router
        , private customDialog: CustomDialogService
        , public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        // Just once to get user role in case of loading directly that component.
        // Remove actions item from columnsToDisplay
        if (!this.defaults.isMersyAdminBranchExcellenceUser && !this.defaults.isMersyReporterBranchExcellenceUser)
            this.columnsToDisplay = this.columnsToDisplay.filter(item => item !== 'actions');
        this.getExcellenceFactors();
    }

    getExcellenceFactors() {
        Utils.httpGetExcellenceFactors(
            this.http,
            this.baseUrl,
            this,
            function (tthis: ExcellenceFactorComponent, list: any) {
                tthis.dataSource.data = list
            },
            null

        )
    }

    onRemoveExcellenceFactor(element: ExcellenceFactor) {
        this.customDialog.openConfirm({
            title: "Delete Excellence Factor",
            message: `Do you want to ${element.Name} - (${element.Description})?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                Utils.httpDeleteExcellenceFactor(
                    this.http
                    , this.baseUrl
                    , element.ExcellenceFactorId
                    , this
                    , function (tthis: ExcellenceFactorComponent, result: any) {
                        if (result) {
                            tthis.getExcellenceFactors();
                            tthis.toastrService.success(`Excellence Factor was deleted successfully!`);
                        }
                        else
                            tthis.toastrService.error(`Excellence factor could not be deleted. Please, contact with administrator`);
                    },
                    function (tthis: ExcellenceFactorComponent, result: any) {
                        tthis.toastrService.error('Excellence factor assigned to a wave. If not, please, contact with administrator');
                    }
                );
            }
        });
    }

    onEditExcellenceFactor(element: ExcellenceFactor) {
        const dialog = this.dialogForm.open(CreateExcellenceFactorPopupComponent, {
            panelClass: ['custom-mat-dialog_v2','dialogNgselect'],
            disableClose: true,
            data: element,
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceFactors()
        })
    }

    openCreateExcellenceFactorPopup() {
        const dialog = this.dialogForm.open(CreateExcellenceFactorPopupComponent, {
            panelClass: ['custom-mat-dialog_v2','dialogNgselect'],
            disableClose: true,
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceFactors()
        })
    }

    onClickDownload() {
        Utils.httpDownloadExcellenceFactors(
            this.http,
            this.baseUrl,
            `Excellence Factor`,
            'factor');
    }

    onClickUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadExcellenceFactor()]);
    }
    onClickTranslDownload() {
        Utils.httpDownloadExcellenceFactorsTransl(
            this.http,
            this.baseUrl,
            `ExcellenceFactorTransl`,
            'Factor');
    }

    onClickTranslUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadTranslFactor()]);
    }

}
