
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Utils } from '../../../../../utils/utils';
import { PasswordStrengthValidator, ConfirmPasswordValidator, GeneratePassword, isValidLocalEmail, CheckContainedPassword } from "../common_form_tools"
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: "internalmaxuser-form",
    templateUrl: './internalmaxuser-form.component.html',
    styleUrls: ['./internalmaxuser-form.component.less'],
    encapsulation: ViewEncapsulation.None
})


export class InternalMaxUserComponent implements OnChanges {


    @Input('tenants') tenants: SelectList[] = [];
    @Input('secondaryTenants') secondaryTenants: SelectList[] = [];
    @Input('domains') domains: SelectList[] = [];

    @Output() onCreateUserTrigger = new EventEmitter<string>();
    @Output() onBackTrigger = new EventEmitter();
    @ViewChild('spinner') spinnerElement!: MatProgressSpinner;

    domain: string = "";
    @Output() onEmailTypeIn = new EventEmitter<string>();
    timeoutFunction: any;
    emailError: string | null = null;
    waiting4Mail: boolean = false;

    ngOnChanges() {
        this.selectAllForDropdownItems(this.secondaryTenants);
    }

    passHide = true;
    confirmPassHide = true;
    public internalMaxForm: FormGroup;
    selectedSecTenString = '';
    constructor(fb: FormBuilder,
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService

    ) {
        this.domain = defaults.stage === 1 ? 'prodtkemax.onmicrosoft.com' : 'devtkebbx.onmicrosoft.com';
        this.internalMaxForm = fb.group(
            {
                userName: ['', Validators.compose([
                    Validators.required, isValidLocalEmail])],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                displayName: ['', Validators.required],
                password: [null, Validators.compose([
                    Validators.required, PasswordStrengthValidator])],
                confirmPassword: ['', [Validators.required]],
                boolPassChange: [false],
                domain: [defaults.stage === 1 ? 'prodtkemax.onmicrosoft.com' : 'devtkebbx.onmicrosoft.com'],
                tenant: [null, Validators.required],
                secondaryTenant: [null],
                email: ['', [Validators.email]]
            }, {
                validator: [ConfirmPasswordValidator("password", "confirmPassword"), CheckContainedPassword("password", "userName")]

        });
    }

    selectAllForDropdownItems(items) {

        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }

    //ngOnChanges() {
    //    this.internalMaxForm.controls['tenant'].setValue(this.tenants[0] ? this.tenants[0].value : '')
    //    this.onPrimaryTenChange();
    //}

    onSecTenChange() {
        this.selectedSecTenString = this.internalMaxForm.controls['secondaryTenant'].value.map(item => item).join(', ');
    }

    //onPrimaryTenChange() {
    //    var filteredDomain = this.domains.filter((obj) => {
    //        return obj.text.toUpperCase().includes(this.internalMaxForm.controls['tenant'].value.concat(".")) === true;
    //    })[0];
    //    if (filteredDomain != null)
    //        this.internalMaxForm.controls['domain'].setValue(filteredDomain.value)
    //    else
    //        this.internalMaxForm.controls['domain'].setValue(this.domains.length > 0 ? this.domains[this.domains.length - 1].value : '')
    //}

    genPass(event: MouseEvent) {
        event.preventDefault();
        const tempPass = GeneratePassword();
        this.internalMaxForm.patchValue({
            password: tempPass,
            confirmPassword: tempPass
        })
    }

    onSubmit(): void {
        Utils.httpCreateUser(
            this.http
            , this.baseUrl
            , this.internalMaxForm.value
            , true
            , this
            , function (tthis: InternalMaxUserComponent, data: string) {
                tthis.onCreateUserTrigger.emit(data);                  
            }
        )
    }

    passHideFunc(event: any) {
        event.preventDefault();
        this.passHide = !this.passHide

    }
    handleConfirmPass(event: any) {
        event.preventDefault();
        this.confirmPassHide = !this.confirmPassHide;
    }
    backButtonHandler(event: any) {
        event.preventDefault();
        this.onBackTrigger.emit();
    }

    changeEmail($event: any) {
        if ($event.key !== "ArrowLeft" && $event.key !== "ArrowRight" && $event.key !== "ArrowUp" && $event.key !== "ArrowDown") {
            this.emailError = null;
            this.waiting4Mail = true;
            if (this.timeoutFunction) {
                clearTimeout(this.timeoutFunction);
                this.timeoutFunction = null;
            }
            this.timeoutFunction = setTimeout(() => {
                if ($event.target.value.trim() !== '') {
                    this.onEmailTypeIn.emit(`${$event.target.value.trim()}@${this.domain}`);
                    this.spinnerElement._elementRef.nativeElement.style.visibility = "visible";
                }
            }, 1000)
        }
    }

    showEmailError(text) {
        this.waiting4Mail = false;
        this.spinnerElement._elementRef.nativeElement.style.visibility = "hidden";
        if (text)
            this.emailError = text;
        else
            this.emailError = null;
    }
}







